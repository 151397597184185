import { lazy, useContext, useMemo } from 'react';
import { ECOABuilderApp } from 'apps.ecoa_builder';
import { SiteManagementApp } from 'apps.site_app';
import { StudyManagementApp } from 'apps.study_management';
import { UserManagementApp } from 'apps.user_management';
import { FlagContext } from 'libs.flags.react';
import { useScopeValidator } from 'libs.react.auth';
import { AclScope } from 'libs.react.types';
import DeveloperFlagsPage from '../../pages/developer_flags/developer_flags.component';
import { SELF_SERVICE_FLAGS } from '../../utils';
const ProfilePage = lazy(() => import('../../pages/profile/profile.page'));
export const useSelfServiceNavigation = () => {
    const flagManager = useContext(FlagContext);
    const hasLibraryAccess = useScopeValidator([AclScope.CONTENT_LIBRARY_LIST, AclScope.CONTENT_LIBRARY_CREATE], {
        anyResource: true,
    });
    const hasStudyManagementAccess = useScopeValidator([AclScope.MOM_STUDY_CREATE]);
    const hasUserManagementAccess = useScopeValidator([AclScope.MOM_PROFILE_LIST, AclScope.MOM_PROFILE_CREATE]);
    const appRoutes = useMemo(() => {
        const isSiteManagementEnabled = flagManager.getValue(SELF_SERVICE_FLAGS.siteManagement);
        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (hasStudyManagementAccess && {
            studies: {
                name: 'Studies',
                path: '/studies',
                redirectTo: 'dashboard',
                hideSideNav: true,
                appRoutes: [
                    {
                        title: 'Studies',
                        path: '/studies',
                        component: StudyManagementApp,
                    },
                ],
            },
        })), (hasLibraryAccess && {
            ecoa: {
                name: 'Library',
                path: '/library',
                redirectTo: 'activities',
                hideSideNav: true,
                appRoutes: [
                    {
                        title: 'Activities',
                        path: 'activities',
                        component: ECOABuilderApp,
                        icon: 'navProtocol',
                    },
                ],
            },
        })), (hasUserManagementAccess && {
            users: {
                name: 'Users',
                path: '/users',
                redirectTo: 'dashboard',
                hideSideNav: true,
                appRoutes: [
                    {
                        title: 'Users',
                        path: '/users',
                        component: UserManagementApp,
                    },
                ],
            },
        })), (isSiteManagementEnabled && {
            site: {
                name: 'Site',
                path: '/site',
                redirectTo: 'dashboard',
                hideSideNav: true,
                appRoutes: [
                    {
                        title: 'Site',
                        path: '/site',
                        component: SiteManagementApp,
                    },
                ],
            },
        })), { profile: {
                name: 'Your profile',
                path: '/profile',
                redirectTo: 'me',
                hideSideNav: true,
                hideFromHeader: true,
                appRoutes: [
                    {
                        title: 'Your profile',
                        path: 'me',
                        component: ProfilePage,
                    },
                ],
            } }), ((process.env.ENABLE_DEVELOPER_FLAGS === 'true' || process.env.ENABLE_DEVELOPER_FLAGS === 'route-only') && {
            flags: {
                name: 'Developer',
                path: '/developer',
                hideSideNav: true,
                hideFromHeader: true,
                appRoutes: [
                    {
                        title: 'Developer flags',
                        path: 'flags',
                        component: DeveloperFlagsPage,
                    },
                ],
            },
        }));
    }, [flagManager, hasLibraryAccess, hasStudyManagementAccess, hasUserManagementAccess]);
    return {
        appRoutes,
        defaultAppSection: Object.values(appRoutes)[0],
    };
};
