import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
export var AlignmentOptions;
(function (AlignmentOptions) {
    AlignmentOptions["LEFT"] = "left";
    AlignmentOptions["CENTER"] = "center";
    AlignmentOptions["RIGHT"] = "right";
})(AlignmentOptions || (AlignmentOptions = {}));
const AlignmentContext = createContext(AlignmentOptions.LEFT);
const SetAlignmentContext = createContext(() => { });
const getInitialValue = (initialValue) => {
    if (initialValue === undefined || typeof initialValue !== 'object' || !(initialValue === null || initialValue === void 0 ? void 0 : initialValue.alignment)) {
        return AlignmentOptions.LEFT;
    }
    switch (initialValue.alignment) {
        case 'left':
            return AlignmentOptions.LEFT;
        case 'center':
            return AlignmentOptions.CENTER;
        case 'right':
            return AlignmentOptions.RIGHT;
        default:
            return AlignmentOptions.LEFT;
    }
};
export const AlignmentContextProvider = ({ children, initialValue, }) => {
    const [alignment, setAlignment] = useState(getInitialValue(initialValue));
    return (_jsx(AlignmentContext.Provider, { value: alignment, children: _jsx(SetAlignmentContext.Provider, { value: setAlignment, children: children }) }));
};
export const useAligmentContext = () => useContext(AlignmentContext);
export const useSetAlignmentContext = () => useContext(SetAlignmentContext);
