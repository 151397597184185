import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Link } from 'libs.nucleus.link';
import { useDateTime } from 'libs.react.hooks';
import { getParticipantNextVisitWindow, getParticipantNumber, getParticipantStatus, } from '../../helpers/participants.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
export const useParticipantsTableColumns = () => {
    const translate = useLocalizeMessage();
    const { environment } = useCurrentEnvironment();
    const { parseDateTimeShort } = useDateTime();
    const participantsColumns = useMemo(() => {
        return [
            {
                header: translate('Participant ID'),
                accessorKey: 'c_number',
                cell: (context) => {
                    const row = context.row.original;
                    return (_jsx(Link, { LinkComponent: RouterLink, to: `/site/study/${environment?.id}/participants/${row._id}`, children: getParticipantNumber(row) }));
                },
            },
            {
                header: translate('Created'),
                accessorKey: 'created',
                cell: (context) => parseDateTimeShort(context.getValue()),
            },
            {
                header: translate('Next visit (window)'),
                accessorKey: 'c_visit_schedule',
                cell: (context) => {
                    return getParticipantNextVisitWindow(context.row.original);
                },
            },
            {
                header: translate('Status'),
                accessorKey: 'c_status',
                cell: (context) => {
                    const status = context.getValue();
                    return getParticipantStatus(status);
                },
            },
        ];
    }, []);
    return {
        participantsColumns,
    };
};
