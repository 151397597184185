import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState, useContext, useMemo, } from 'react';
import { v4 } from 'uuid';
import { DefaultActivityId, DefaultTimelineId, SDKVisit, } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Checkbox } from 'libs.nucleus.checkbox';
import { FormSections } from 'libs.nucleus.form_sections';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Input } from 'libs.nucleus.input';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { RadioGroup, RadioGroupOption } from 'libs.nucleus.radio_group';
import { Select } from 'libs.nucleus.select';
import { Text } from 'libs.nucleus.typography';
import { StudyConfigurationContext } from '../../../contexts';
import { useTimeframes } from '../../../hooks';
const DEFAULT_VISIT_FORM = {
    code: '',
    daysFromStart: 0,
    isOptional: false,
    isTelevisitEnabled: false,
    minus: { value: '0', label: '--' },
    name: '',
    plus: { value: '0', label: '--' },
    sequentialActivityOrder: false,
    status: { value: '', label: '-select status-' },
    visitType: 'standard',
};
const getNewEncounterDetails = (visitForm, initialVisit) => {
    const details = initialVisit?.getDetails();
    return SDKVisit.createDetails({
        activityIds: details?.activityIds || [],
        code: visitForm.code,
        daysAfterRelative: visitForm.daysFromStart,
        duration: details?.duration || '',
        encounterAnchor: visitForm.name,
        endEncounterId: details?.endEncounterId || '',
        isOnboarding: details?.isOnboarding ?? false,
        isOptional: visitForm.isOptional,
        isScheduled: details?.isScheduled ?? true,
        offsetAfterWindow: Number(visitForm.plus?.value || 0),
        offsetBeforeWindow: Number(visitForm.minus?.value || 0),
        sequentialActivityOrder: visitForm.sequentialActivityOrder,
        televisitEnabled: visitForm.isTelevisitEnabled,
        visitConfirmation: details?.visitConfirmation || '',
        visitType: visitForm.visitType,
    });
};
export const ScheduleTimelineVisitModal = ({ initialVisit, isCloning, onCancel, onSave, earlyWithdrawalExists, unscheduledVisitExists, }) => {
    const { currentStudy, studyId, visits, currentTimelineId } = useContext(StudyConfigurationContext);
    const { logEvent } = useContext(FirebaseAppContext);
    const translate = useLocalizeMessage();
    const { dayOptions } = useTimeframes();
    /**
     * List of participant statuses available to used as Dropdown Options
     */
    const statusOptions = useMemo(() => {
        const { participantStatuses } = currentStudy.getStudyMetadata();
        const options = [{ label: translate('-select status-'), value: '' }];
        return options.concat(participantStatuses.map((status) => ({ label: status.value, value: status.id })));
    }, [currentStudy]);
    const [visitForm, setVisitForm] = useState(() => {
        let initialVisitForm = DEFAULT_VISIT_FORM;
        if (initialVisit) {
            const encounterDetails = initialVisit.getDetails();
            const initialStatus = currentStudy
                .getParticipantStatusConditions()
                .find((savedCondition) => savedCondition.encounterId === initialVisit.id);
            const minusOption = dayOptions.find((option) => option.value === String(encounterDetails.offsetBeforeWindow));
            const plusOption = dayOptions.find((option) => option.value === String(encounterDetails.offsetAfterWindow));
            const statusOption = statusOptions.find((option) => option.value === initialStatus?.updatesParticipantStatusTo);
            initialVisitForm = {
                ...initialVisitForm,
                name: !isCloning ? initialVisit.name : '',
                visitType: encounterDetails.visitType === 'supplemental' ? 'standard' : encounterDetails.visitType,
                ...(!isCloning && { code: encounterDetails.code }),
                daysFromStart: encounterDetails.daysAfterRelative,
                isOptional: encounterDetails.isOptional,
                isTelevisitEnabled: encounterDetails.televisitEnabled,
                minus: minusOption || dayOptions[0],
                plus: plusOption || dayOptions[0],
                sequentialActivityOrder: encounterDetails.sequentialActivityOrder || false,
                status: statusOption || statusOptions[0],
            };
        }
        return initialVisitForm;
    });
    const [isPristine, setIsPristine] = useState(true);
    const nameInput = useRef(null);
    const hasSameName = visits.length > 0 &&
        visits.some((visit) => visit.name.trim().toLocaleLowerCase() === visitForm.name.trim().toLocaleLowerCase() &&
            (isCloning || initialVisit?.id !== visit.id));
    const isSaveEnabled = visitForm.name && !isPristine && !hasSameName;
    useEffect(() => {
        if (nameInput.current) {
            nameInput.current.focus();
        }
    }, []);
    const updateVisitForm = (partialVisitForm) => {
        setVisitForm((oldVisitForm) => ({ ...oldVisitForm, ...partialVisitForm }));
    };
    const handleInputChange = (event) => {
        updateVisitForm({ [event.target.id]: event.target.value });
        setIsPristine(false);
    };
    const handleDaysFromStartChange = (event) => {
        updateVisitForm({ daysFromStart: Number(event.target.value) });
        setIsPristine(false);
    };
    const handleIsOptionalChange = (newValue) => {
        updateVisitForm({ isOptional: newValue });
        setIsPristine(false);
    };
    const handleIsTelevisitEnabled = (newValue) => {
        updateVisitForm({ isTelevisitEnabled: newValue });
        setIsPristine(false);
    };
    const handleSelectChange = (id, option) => {
        updateVisitForm({ [id]: option });
        setIsPristine(false);
    };
    const handleActivityOrderChange = (sequentialActivityOrder) => {
        updateVisitForm({ sequentialActivityOrder });
        setIsPristine(false);
    };
    const handleVisitTypeChange = (visitType) => {
        updateVisitForm({ visitType });
        setIsPristine(false);
    };
    const onSubmit = (event) => {
        event.preventDefault();
        if (isSaveEnabled) {
            handleSave();
        }
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && isSaveEnabled) {
            handleSave();
        }
    };
    /**
     * It converts the different status conditions into the format required by the SDK
     * This SDK format has (for now) some fields that are not used in the UI and that will be removed soon
     */
    const generateParticipantStatusConditions = (visitId) => {
        // We filter out any condition belonging to this activity and timeline
        const filteredParticipantStatusConditions = currentStudy
            .getParticipantStatusConditions()
            .filter((savedCondition) => savedCondition.encounterId !== visitId);
        if (!visitForm.status.value) {
            return filteredParticipantStatusConditions;
        }
        const newStatusCondition = {
            activityId: DefaultActivityId.VISIT_CONFIRMATION,
            encounterId: visitId,
            id: v4(),
            scheduleTimelineId: currentTimelineId,
            triggerType: 'activity_completion',
            updatesParticipantStatusTo: visitForm.status.value,
        };
        return [...filteredParticipantStatusConditions, newStatusCondition];
    };
    const handleSave = () => {
        if (!initialVisit) {
            const visit = createVisit(currentTimelineId);
            currentStudy.addVisitConfirmationActivity(currentTimelineId, visit);
            currentStudy.setParticipantStatusConditions(generateParticipantStatusConditions(visit.id));
            onSave(visit);
            logEvent('study_visit_added', { study_id: studyId, visit_name: visitForm.name });
        }
        else {
            if (isCloning) {
                const newVisit = initialVisit.cloneVisit({ name: visitForm.name });
                newVisit.setDetails(getNewEncounterDetails(visitForm, newVisit));
                currentStudy.setParticipantStatusConditions(generateParticipantStatusConditions(newVisit.id));
                onSave(newVisit);
                logEvent('study_visit_cloned', {
                    study_id: studyId,
                    visit_id: visitForm.name,
                    visit_cloned_from: initialVisit.name,
                });
            }
            else {
                const visitActivityOrderChanged = initialVisit.getDetails().sequentialActivityOrder !== visitForm.sequentialActivityOrder;
                initialVisit.name = visitForm.name;
                initialVisit.setDetails(getNewEncounterDetails(visitForm, initialVisit));
                currentStudy.setParticipantStatusConditions(generateParticipantStatusConditions(initialVisit.id));
                if (visitActivityOrderChanged && visitForm.sequentialActivityOrder) {
                    initialVisit.resetParticipantActivityStartConditions();
                }
                onSave(initialVisit);
                logEvent('study_visit_edited', { study_id: studyId, visit_id: visitForm.name });
            }
        }
    };
    const createVisit = (scheduleTimelineId) => {
        return new SDKVisit({
            name: visitForm.name,
            details: getNewEncounterDetails(visitForm),
            scheduleTimelineId,
        });
    };
    const saveButton = {
        label: isCloning ? translate('Clone') : initialVisit ? translate('Save') : translate('Create'),
        onClick: handleSave,
        disabled: !isSaveEnabled,
    };
    const cancelButton = {
        label: translate('Cancel'),
        onClick: onCancel,
    };
    const formSections = [
        {
            title: translate('Visit details'),
            description: translate('Give your visit a name and fill out the visit details'),
            content: (_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(Input, { dataTestId: 'create-visit-name-input', errorMessage: translate('A visit with this name already exists. Please choose a different name.'), hasError: hasSameName, id: 'name', label: translate('Visit name'), onChange: handleInputChange, ref: nameInput, required: true, value: visitForm.name }), !initialVisit?.getDetails().isOnboarding && (_jsxs(RadioGroup, { dataTestId: 'create-visit-type-radio-group', onChange: handleVisitTypeChange, value: visitForm.visitType, children: [_jsx(RadioGroupOption, { option: {
                                    value: 'standard',
                                    label: translate('Scheduled'),
                                    description: translate('A visit that is a part of the planned schedule of events throughout the study'),
                                } }), _jsx(RadioGroupOption, { option: {
                                    value: 'unscheduled',
                                    label: translate('Unscheduled'),
                                    description: translate('Used to generate activities outside of the planned schedule as needed. Adding this visit type once will make it always available to site users.  Once one is complete, the next will become available'),
                                }, disabled: initialVisit?.getDetails().visitType !== 'unscheduled' && unscheduledVisitExists }), _jsx(RadioGroupOption, { option: {
                                    value: 'end_of_study',
                                    label: translate('Early withdrawal'),
                                    description: translate('Occurs when the participant needs to withdraw from the study early. All active activities not in this visit will be disabled at time of early withdraw confirmation'),
                                }, disabled: initialVisit?.getDetails().visitType !== 'end_of_study' && earlyWithdrawalExists })] })), _jsx(Input, { dataTestId: 'create-visit-code-input', description: translate('The visit code will associate activity responses to the visit and is displayed in data transfers.'), id: 'code', label: translate('Visit code'), onChange: handleInputChange, value: visitForm.code }), !initialVisit?.getDetails().isOnboarding && (_jsx(Checkbox, { dataTestId: 'create-visit-skippable-checkbox', checked: visitForm.isOptional, onChange: handleIsOptionalChange, label: translate('Allow visit to be skipped') })), _jsx(Checkbox, { dataTestId: 'create-visit-enable-televisit-checkbox', checked: visitForm.isTelevisitEnabled, onChange: handleIsTelevisitEnabled, label: translate('Enable televisit') })] })),
        },
        {
            title: translate('Activity order'),
            description: translate('Set the order that activities will become available in'),
            content: (_jsxs(RadioGroup, { dataTestId: 'create-visit-activity-order-radio-group', onChange: handleActivityOrderChange, value: visitForm.sequentialActivityOrder, children: [_jsx(RadioGroupOption, { option: {
                            value: false,
                            label: translate('Concurrent'),
                            description: translate('Participant activities will become available all at the same time'),
                        } }), _jsx(RadioGroupOption, { option: {
                            value: true,
                            label: translate('Sequential'),
                            description: translate('All participant activities in this visit start after the visit confirmation and must be completed in the order that they are listed in the schedule'),
                        } })] })),
        },
    ];
    if (!initialVisit || currentTimelineId !== DefaultTimelineId.ONBOARDING) {
        formSections.push({
            title: translate('Status assignment'),
            description: translate('Assign participants a status to participants at the start of this visit'),
            content: (_jsx(Select, { dataTestId: 'create-visit-status-option', onChange: (option) => handleSelectChange('status', option), options: statusOptions, value: visitForm.status, width: 'lg' })),
        });
    }
    if (!initialVisit?.getDetails().isOnboarding) {
        formSections.push({
            title: translate('Window'),
            description: translate('Select when this visit should become available in your schedule timeline relative to the first visit'),
            content: (_jsxs(_Fragment, { children: [_jsx(Input, { dataTestId: 'create-visit-days-from-start-input', disabled: visits.length === 0, id: 'daysFromStart', label: translate('Days from start'), min: '0', onChange: handleDaysFromStartChange, type: 'number', value: visitForm.daysFromStart, width: 'xs' }), _jsx(Text, { size: 'sm', className: 'font-medium pt-4', children: translate('Offset (optional)') }), _jsxs("div", { className: 'flex gap-4', children: [_jsx(Select, { dataTestId: 'create-visit-minus-select', description: translate('Days before'), onChange: (option) => handleSelectChange('minus', option), options: dayOptions, value: visitForm.minus, width: 'xs' }), _jsx(Select, { dataTestId: 'create-visit-plus-select', description: translate('Days after'), onChange: (option) => handleSelectChange('plus', option), options: dayOptions, value: visitForm.plus, width: 'xs' })] })] })),
        });
    }
    return (_jsx(ModalWindow, { title: isCloning ? translate('Clone visit') : initialVisit ? translate('Edit visit') : translate('Create visit'), isOpen: true, closeWindow: onCancel, footerPrimaryActionButton: saveButton, footerSecondaryActionButtons: [cancelButton], width: 'full', children: _jsx("form", { className: 'flex min-h-[16rem] flex-col', onSubmit: onSubmit, autoComplete: 'off', onKeyUp: handleEnterPress, children: _jsx(FormSections, { sections: formSections }) }) }));
};
