import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { FormSections } from 'libs.nucleus.form_sections';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { Spinner } from 'libs.nucleus.spinner';
import { useBooleanState, useToastNotification } from 'libs.react.hooks';
import { ParticipantEmailField } from './form_items/participant_email.component';
import { ParticipantLanguageField } from './form_items/participant_language.component';
import { ParticipantNumberField } from './form_items/participant_number.component';
import { createParticipant, getParticipantFormInitialValues } from './helpers/participant_form.helpers';
import { ParticipantInviteModal } from './participant_invite_modal.component';
import { getApiErrorMessage } from '../../helpers/api.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { useForm } from '../../hooks/use_form';
import { useOrgContext } from '../../hooks/use_org_context';
import { useSiteContext } from '../../hooks/use_site_context';
export const ParticipantFormModal = ({ isModalOpen, participant, onClose }) => {
    const translate = useLocalizeMessage();
    const org = useOrgContext();
    const { environment } = useCurrentEnvironment();
    const site = useSiteContext();
    const { form, isFieldtouched, onFieldChange, onSelectFieldChange } = useForm({
        initialValues: getParticipantFormInitialValues(participant),
    });
    const [isSaving, , , toggleIsSaving] = useBooleanState(false);
    const [modeState, setModeState] = useState();
    const [createdParticipant, setCreatedParticipant] = useState();
    const [isInviteModalOpen, setIsInviteModalOpen, setIsInviteModalClose] = useBooleanState(false);
    const { addNotification } = useToastNotification();
    const navigate = useNavigate();
    const onInviteModalDone = (participant) => {
        navigate(`/site/study/${environment?.id}/participants/${participant._id}`);
    };
    const errors = {
        email: form?.email && !isEmail(form?.email) ? translate('Invalid email address') : undefined,
        language: !form?.language?.value ? translate('Language is required') : undefined,
    };
    const formHasErrors = Object.values(errors).some((error) => !!error);
    const saveForm = async (mode) => {
        if (!environment) {
            throw new Error('Environment not defined');
        }
        try {
            toggleIsSaving();
            setModeState(mode);
            const participant = await createParticipant({
                environment,
                org,
                site,
                form,
            });
            if (participant) {
                addNotification({
                    title: '',
                    subtitle: translate(`Participant record created`),
                    type: 'success',
                });
                if (mode === 'create_only') {
                    navigate(`/site/study/${environment?.id}/participants/${participant._id}`);
                }
                if (mode === 'create_invite') {
                    setCreatedParticipant(participant);
                    onClose();
                    setIsInviteModalOpen();
                }
            }
        }
        catch (e) {
            console.error('error', e);
            const message = getApiErrorMessage(e);
            addNotification({
                title: '',
                subtitle: message ?? translate('We were unable to process your request. Try again later.'),
                type: 'error',
            });
        }
        finally {
            setModeState(undefined);
            toggleIsSaving();
        }
    };
    const formSections = [
        {
            title: translate('Details'),
            content: (_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(ParticipantLanguageField, { form: form, errors: errors, isFieldtouched: isFieldtouched, onSelectFieldChange: onSelectFieldChange }), _jsx(ParticipantEmailField, { form: form, errors: errors, onFieldChange: onFieldChange, isFieldtouched: isFieldtouched }), _jsx(ParticipantNumberField, { form: form, errors: errors, onFieldChange: onFieldChange, isFieldtouched: isFieldtouched })] })),
        },
    ];
    const savingButton = (_jsxs("div", { className: 'flex items-center gap-3', children: [_jsx(Spinner, { size: 'sm' }), " ", translate('Saving'), "..."] }));
    return (_jsxs(_Fragment, { children: [createdParticipant && (_jsx(ParticipantInviteModal, { isModalOpen: isInviteModalOpen, participant: createdParticipant, onClose: setIsInviteModalClose, onDone: () => onInviteModalDone(createdParticipant) })), _jsx(ModalWindow, { title: translate('Create Participant'), isOpen: isModalOpen, closeWindow: onClose, footerSecondaryActionButtons: [
                    {
                        label: translate('Create and skip invite'),
                        onClick: () => saveForm('create_only'),
                        disabled: formHasErrors || isSaving,
                        content: modeState === 'create_only' ? savingButton : undefined,
                        id: 'create-participant-skip-invite',
                        dataTestId: 'create-participant-skip-invite',
                    },
                    { label: translate('Cancel'), onClick: onClose },
                ], footerPrimaryActionButton: {
                    disabled: formHasErrors || isSaving,
                    label: translate('Create and invite'),
                    content: modeState === 'create_invite' ? savingButton : undefined,
                    onClick: () => saveForm('create_invite'),
                    id: 'create-participant-invite',
                    dataTestId: 'create-participant-invite',
                }, width: 'full', children: _jsx("form", { className: 'mt-10 mb-24', children: _jsx(FormSections, { sections: formSections }) }) })] }));
};
