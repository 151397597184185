import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useMatch } from 'react-router-dom';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Tabs } from 'libs.nucleus.tabs';
import { PageHeader, PageWrapper } from 'libs.react.components';
import { ParticipantDetails } from '../../components/participant_details/participant_details.component';
import { ParticipantDocuments } from '../../components/participant_documents/participant_documents.component';
import { ParticipantVisits } from '../../components/participant_visits/participant_visits.component';
import { SiteContext } from '../../contexts/site';
import { getApiInstance } from '../../helpers/api.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
export const ParticipantDetailsPage = () => {
    const translate = useLocalizeMessage();
    const { environment } = useCurrentEnvironment();
    const { site } = useContext(SiteContext);
    const match = useMatch('/site/study/:studyId/participants/:participantId');
    const participantId = match?.params.participantId;
    const getParticipantData = async () => {
        if (!environment) {
            return [];
        }
        try {
            const response = await getApiInstance(environment).get(`/v2/routes/site_subjects/${site?._id}`, {
                params: {
                    expand: ['c_visit_schedule'],
                    where: JSON.stringify({ _id: participantId }),
                },
            });
            return response?.data?.data[0] || {};
        }
        catch (error) {
            return {};
        }
    };
    const { data: participantData, isLoading } = useQuery({
        queryKey: ['participantDetails', participantId],
        queryFn: getParticipantData,
        enabled: true,
    });
    const breadcrumbsPages = [
        { name: translate('Participants'), path: `/site/study/${environment?.id}/participants` },
        { name: participantData?.c_number || translate('Participant Detail'), path: '' },
    ];
    return (_jsxs(PageWrapper, { children: [_jsx(PageHeader, { breadcrumbPages: breadcrumbsPages, title: participantData?.c_number || '' }), _jsx(Tabs, { tabLabels: [translate('Details'), translate('Visits'), translate('Documents'), translate('Audit History')], tabContent: [
                    _jsx(ParticipantDetails, { participant: participantData, participantDetailsLoading: isLoading }, 'participant-details'),
                    _jsx(ParticipantVisits, {}, 'participant-visits'),
                    _jsx(ParticipantDocuments, { participantId: participantId }, 'participant-documents'),
                    _jsx("p", { children: "Participant Audit History go here" }, 'participant-audit-history'),
                ], defaultIndex: 0, onTabChange: () => { } })] }));
};
