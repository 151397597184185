import { AssetTypes } from 'libs.localization.common';
import { AssetRole, StudyAssetType } from '../types';
export const mapStudyAssetToRole = (asset) => {
    switch (asset) {
        case AssetTypes.ACTIVITY_NAMES:
            return AssetRole.BOTH;
        case AssetTypes.SIGN_IN_SUPPORT_INFORMATION:
        case AssetTypes.SIGN_IN_STUDY_NAME_PII:
        case AssetTypes.SIGN_IN_STUDY_NAME:
            return AssetRole.PARTICIPANT;
        case AssetTypes.PROTOCOL_SCHEDULE:
        case AssetTypes.PARTICIPANT_STATUSES:
        case AssetTypes.SITE_VISIT_SCHEDULE:
            return AssetRole.SITE;
        default:
            return AssetRole.BOTH;
    }
};
export const getTypeByAvailableTo = (availableTo) => {
    switch (availableTo) {
        case 'patient_only':
            return StudyAssetType.PARTICIPANT_RESOURCES;
        case 'site_only':
            return StudyAssetType.SITE_RESOURCES;
        case 'site_patient':
        default:
            return StudyAssetType.SITE_PARTICIPANT_RESOURCES;
    }
};
export const getRolebyAvailableTo = (availableTo) => {
    switch (availableTo) {
        case 'patient_only':
            return AssetRole.PARTICIPANT;
        case 'site_only':
            return AssetRole.SITE;
        case 'site_patient':
        default:
            return AssetRole.BOTH;
    }
};
