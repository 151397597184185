import { TextNode } from 'lexical';
export class CustomTextNode extends TextNode {
    static getType() {
        return 'custom_text';
    }
    static importJSON(node) {
        return super.importJSON(node);
    }
    static clone(node) {
        return new CustomTextNode(node.getTextContent(), node.getKey());
    }
    exportJSON() {
        return Object.assign(Object.assign({}, super.exportJSON()), { type: 'custom_text', version: 1 });
    }
    wrapElement(element, tag) {
        const el = document.createElement(tag);
        el.appendChild(element);
        return el;
    }
    createDOM(config) {
        return super.createDOM(config);
    }
    /**
     * Overrides the exportDOM method to remove additional tags, classes, styles, and attributes from text elements.
     * In Lexical text nodes are represented as spans.
     * - <strong> is wrapped with a <b> for semantic reasons <strong><b>textContent</strong><b>
     * - <em> is wrapped with a <i> for semantic reasons <i><em>textContent</i><em>
     *
     * Sanitised output:
     * Example: `<p><span>Text</span></p>` => `<p>Text</p>`
     * Example: `<span>Text</span>` => be`Text`
     * Example: `<b><strong>Text</strong></b>` => `<b>Text</b>`
     * Example: `<i><em>Text</em></i>` should be `<i>Text</i>`
     * Example: `<u>Text</u>` should be `<u>Text</u>`
     *
     * @override
     */
    exportDOM(editor) {
        let { element } = super.exportDOM(editor);
        if (element instanceof HTMLElement) {
            // Remove unwanted attributes
            element.removeAttribute('class');
            element.removeAttribute('style');
            const textContent = this.getTextContent();
            element = new Text(textContent);
            // Support <b>, <u>, <i>
            if (this.hasFormat('bold') && textContent) {
                element = this.wrapElement(element, 'b');
            }
            if (this.hasFormat('italic') && textContent) {
                element = this.wrapElement(element, 'i');
            }
            if (this.hasFormat('underline') && textContent) {
                element = this.wrapElement(element, 'u');
            }
        }
        return {
            element,
        };
    }
}
