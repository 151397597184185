import { useQuery, QueryClientContext, keepPreviousData } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { DEFAULT_PAGE_SIZE } from 'libs.react.constants';
import { useApiClient } from 'libs.react.contexts';
import { LibraryEndpoint, LibraryResourceStatus } from 'libs.react.types';
import { StudyConfigurationContext } from '../../contexts';
export const useStudyVersion = ({ pageSize = DEFAULT_PAGE_SIZE, search = '' } = {}) => {
    const libraryClient = useApiClient("library" /* ApiClientService.LIBRARY */);
    const { studyId } = useContext(StudyConfigurationContext);
    const queryClient = useContext(QueryClientContext);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize });
    const retrieveVersions = async () => {
        const searchParams = new URLSearchParams({
            limit: pageSize.toString(),
            page: (pagination.pageIndex + 1).toString(),
            status: LibraryResourceStatus.PUBLISHED,
            studyId,
            ...(search && { search }),
        });
        const endpoint = `${LibraryEndpoint.GET_STUDY_CONFIGS}?${searchParams.toString()}`;
        const { data: response } = await libraryClient.get(endpoint);
        const versions = response.data.map((version) => ({
            ...version,
            ...(typeof version.metadata === 'string' && { metadata: JSON.parse(version.metadata) }),
        }));
        return { versions, totalPages: Math.ceil(response.total / pagination.pageSize) };
    };
    const { data = { totalPages: 0, versions: [] }, isError, isFetching, isLoading, } = useQuery({
        queryKey: ['study-configs', { page: pagination.pageIndex + 1 }, search],
        queryFn: retrieveVersions,
        placeholderData: keepPreviousData,
    });
    const invalidateVersions = async () => {
        await queryClient?.invalidateQueries({ queryKey: ['study-configs'] });
    };
    return {
        invalidateVersions,
        isError,
        isFetching,
        isLoading,
        pagination,
        setPagination,
        totalPages: data.totalPages,
        versions: data.versions,
    };
};
