import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { ActivityType } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Dropdown } from 'libs.nucleus.dropdown';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Icon } from 'libs.nucleus.icon';
import { useDialog } from 'libs.nucleus.modal_dialog';
import { useBooleanState, useToastNotification } from 'libs.react.hooks';
import { ActivityDependencyUtils } from './activity_dependencies';
import { RemoteActivityEditModal } from './remote_activity_edit_modal';
import { StudyConfigurationContext } from '../../../contexts';
import { useActivityUtils } from '../../../hooks';
import { getActivityWarningMessage, hasDependentActivities, hasDependentNotifications } from '../../../utils/study';
import { EnhancedMatrixRow } from '../../enhanced_matrix';
import { ScheduleTimelineEditActivityModal } from '../schedule_timeline_activity_modal';
const ACTIVITY_STYLES_MAP = {
    [ActivityType.PARTICIPANT]: { background: 'bg-purple-50 hover:bg-purple-100' },
    [ActivityType.SITE]: { background: 'bg-green-50 hover:bg-green-100' },
    [ActivityType.SITE_WORKFLOW]: { background: 'bg-blue-50 hover:bg-blue-100' },
};
export const ScheduleTimelineRemoteRow = ({ item: remoteActivity, dataTestId, headers, getCellStyles, width, rowIndex, defaultCellWidth, }) => {
    const { addNotification } = useToastNotification();
    const translate = useLocalizeMessage();
    const dialog = useDialog();
    const { assignStudy, currentStudy, isReadOnlyMode, visits, currentTimelineId, studyId } = useContext(StudyConfigurationContext);
    const [isRemoteActivityModalOpen, openRemoteActivityModal, closeRemoteActivityModal] = useBooleanState(false);
    const [isEditActivityModalOpen, openEditActivityModal, closeEditActivityModal] = useBooleanState(false);
    const { getActivityIcon } = useActivityUtils();
    const { logEvent } = useContext(FirebaseAppContext);
    const activity = currentStudy.getActivityById(remoteActivity.scheduledActivity.activityId);
    const onDeleteRemoteActivity = async (scheduledActivityId) => {
        const dependentActivities = hasDependentActivities(remoteActivity.scheduledActivity.activityId, currentStudy, currentTimelineId);
        const hasNotificationsDependency = hasDependentNotifications(remoteActivity.scheduledActivity.activityId, currentStudy, currentTimelineId);
        const ok = await dialog.open({
            title: translate('Delete remote activity'),
            body: translate(getActivityWarningMessage(dependentActivities, hasNotificationsDependency)),
            actionButtons: [{ isDestructive: true, label: translate('Delete') }, { label: translate('Cancel') }],
        });
        if (ok) {
            currentStudy.removeScheduledActivity(currentTimelineId, scheduledActivityId);
            assignStudy(currentStudy);
            addNotification({
                title: translate('Remote activity removed'),
                subtitle: translate('The remote activity "{name}" has been removed from the timeline.', {
                    name: remoteActivity.name,
                }),
                type: 'success',
            });
            logEvent('remote_activity_deleted', {
                study_id: studyId,
                remote_activity_id: remoteActivity.id,
                remote_activity_name: remoteActivity.name,
                timeline_id: currentTimelineId,
            });
        }
    };
    const activityOptions = [
        {
            id: 'Edit',
            content: translate('Edit'),
            onClick: openEditActivityModal,
            disabled: isReadOnlyMode,
            hidden: remoteActivity.type !== ActivityType.PARTICIPANT,
        },
        {
            id: 'Delete',
            content: translate('Delete'),
            destructive: true,
            onClick: async () => onDeleteRemoteActivity(remoteActivity.id),
            disabled: isReadOnlyMode,
        },
    ];
    const onActivitySave = (activities) => {
        addNotification({
            title: translate('Activity updated'),
            subtitle: translate('The activity "{message}" has been updated.', { message: activities[0].name }),
            type: 'success',
        }, 5000);
        logEvent('activity_updated', {
            study_id: studyId,
            activity_id: activities[0].id,
            activity_name: activities[0].name,
            timeline_id: currentTimelineId,
        });
        assignStudy(currentStudy);
        closeEditActivityModal();
    };
    const onActivityScheduleSave = (activitySchedule) => {
        const baseCondition = {
            activityId: remoteActivity.scheduledActivity.activityId,
            scheduleTimelineId: currentTimelineId,
        };
        const endConditions = ActivityDependencyUtils.convertEndConditions({
            conditions: activitySchedule.endConditions,
            baseCondition,
            isRemoteActivity: true,
        });
        const startConditions = ActivityDependencyUtils.convertStartConditions({
            conditions: activitySchedule.startConditions,
            baseCondition,
            isRemoteActivity: true,
        });
        currentStudy.editScheduledActivityTiming({
            timelineId: currentTimelineId,
            scheduledActivityId: remoteActivity.scheduledActivity.id,
            timing: {
                ...remoteActivity.scheduledActivity.timing,
                startConditions,
                endConditions,
                timingDetails: { specificTime: activitySchedule.specificStartTime },
                ...(activitySchedule.type === 'recurring' &&
                    activitySchedule.repetition?.repetitionEvery && {
                    recurrenceRule: {
                        frequency: activitySchedule.repetition.repetitionEveryPeriod.value,
                        interval: Number(activitySchedule.repetition.repetitionEvery),
                        ...(activitySchedule.repetition.count && { count: Number(activitySchedule.repetition.count) }),
                    },
                }),
            },
        });
        assignStudy(currentStudy);
        closeRemoteActivityModal();
        addNotification({
            title: translate('Remote Activity Updated'),
            subtitle: translate('The configuration for the remote activity "{name}" has been updated successfully', {
                name: remoteActivity.name,
            }),
            type: 'success',
        });
        logEvent('remote_activity_updated', {
            study_id: studyId,
            activity_id: remoteActivity.scheduledActivity.id,
            activity_name: remoteActivity.name,
            timeline_id: currentTimelineId,
        });
    };
    const filteredVisits = visits.filter((visit) => visit.getDetails().visitType === 'standard');
    // Find the index of the start and end triggers for the remote activity in the visits array
    let cellWidth = defaultCellWidth;
    let startIndex = filteredVisits.findIndex((visit) => remoteActivity.startTrigger.includes(visit.name));
    startIndex = startIndex === -1 ? 0 : startIndex;
    let endIndex = filteredVisits.findIndex((visit) => remoteActivity.endTrigger.includes(visit.name));
    endIndex = endIndex === -1 ? filteredVisits.length : endIndex;
    if (endIndex > startIndex) {
        // If the end trigger is set, calculate the width of the cell based on the start and end triggers
        cellWidth = cellWidth * (endIndex - startIndex);
    }
    return (_jsxs(_Fragment, { children: [_jsx(EnhancedMatrixRow, { dataTestId: dataTestId, width: width, rowIndex: rowIndex, children: headers.map((header, index) => {
                    // We hide as many extra cells as the cells consumed by remote activities happening in multiple visits to ensure the row end is aligned
                    if ((index - 1 > startIndex && endIndex === -1) || (index - 1 > startIndex && index <= endIndex)) {
                        return null;
                    }
                    // we subtract 1 from the index to account for the activity name cell when calculating the width of the cell and the background gradient
                    return (_jsxs("div", { className: getCellStyles(index), style: {
                            width: header.key === 'activityName'
                                ? header.width
                                : index - 1 === startIndex
                                    ? cellWidth
                                    : defaultCellWidth,
                            padding: '0.25rem',
                            flexShrink: 0,
                        }, children: [header.key === 'activityName' && (_jsxs("div", { className: 'flex h-full w-[17.5rem] items-center justify-between pl-4 pr-1 font-sans text-xs font-medium group', children: [getActivityIcon(remoteActivity.type), _jsx("span", { className: 'flex-1 ml-4 line-clamp-2', "data-testid": 'schedule-timeline-remote-row-name', title: remoteActivity.name, children: remoteActivity.name }), _jsx("div", { className: 'opacity-0 group-hover:opacity-100 group-focus:opacity-100 group-focus-within:opacity-100 has-[button[aria-expanded="true"]]:opacity-100', children: _jsx(Dropdown, { items: activityOptions, variant: 'kebab' }) })] })), header.key !== 'activityName' && (_jsx("div", { className: `group flex h-full w-full items-center justify-center rounded-[0.25rem] ${index - 1 === startIndex ? ACTIVITY_STYLES_MAP[remoteActivity.type].background : ''}`, children: !isReadOnlyMode && index - 1 === startIndex && (_jsx("div", { className: 'absolute left-2 rounded-full p-[0.1875rem] bg-white text-neutral-400 flex items-center justify-center opacity-0 cursor-pointer group-hover:opacity-100 group-focus:opacity-100 group-focus-within:opacity-100', "data-testid": `remote-activity-cog-${remoteActivity.name}`, onClick: openRemoteActivityModal, children: _jsx(Icon, { name: 'cog', color: 'current', size: 'xs' }) })) }))] }, `timeline-${remoteActivity.id}-remote-cell-${header.key}`));
                }) }), isRemoteActivityModalOpen && (_jsx(RemoteActivityEditModal, { initialActivity: remoteActivity.scheduledActivity, activityName: remoteActivity.name, onCancel: closeRemoteActivityModal, onSave: onActivityScheduleSave, activityType: remoteActivity.type })), isEditActivityModalOpen && activity && (_jsx(ScheduleTimelineEditActivityModal, { initialActivity: activity, onCancel: closeEditActivityModal, onSave: onActivitySave }))] }));
};
