import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { I18nUtils } from '@medable/i18n-core';
import { LocaleContext, useLocalizeMessage } from 'libs.nucleus.i18n';
import { Link } from 'libs.nucleus.link';
import { Spinner } from 'libs.nucleus.spinner';
import { Text } from 'libs.nucleus.typography';
import { ListInformation } from 'libs.react.components';
import { useBooleanState, useDateTime } from 'libs.react.hooks';
import { getParticipantNextVisitWindow } from '../../helpers/participants.helpers';
import { ParticipantInviteModal } from '../participant_form/participant_invite_modal.component';
export const ParticipantDetails = ({ participant, participantDetailsLoading }) => {
    const translate = useLocalizeMessage();
    const currentLocale = useContext(LocaleContext);
    const { parseDateTimeShort } = useDateTime();
    const [isInviteModalOpen, setIsInviteModalOpen, setIsInviteModalClose] = useBooleanState(false);
    if (!participant || participantDetailsLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    const detailItems = [
        {
            title: translate('Language'),
            description: I18nUtils.getLanguageLabel(participant.c_locale, currentLocale, false),
        },
        {
            title: translate('Enrolled'),
            description: participant.c_enrollment_date && parseDateTimeShort(participant.c_enrollment_date),
        },
        { title: translate('Email'), description: participant.c_email },
        {
            title: translate('Invite Code'),
            description: (_jsxs("div", { className: 'flex justify-between', children: [_jsx(Text, { size: 'sm', className: 'font-medium text-text-primary', children: participant.c_access_code }), _jsx("div", { children: _jsx(Link, { "data-testid": 'add-view-invite', href: '#', onClick: setIsInviteModalOpen, children: translate('View') }) })] })),
        },
        { title: translate('Cohort'), description: participant.c_visit_schedule?.c_name },
        {
            title: translate('Consented'),
            description: participant.c_status.toLowerCase() === 'consented' ? 'Consented' : 'Not Consented',
        },
        { title: translate('Next Visit'), description: getParticipantNextVisitWindow(participant) },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(ParticipantInviteModal, { isModalOpen: isInviteModalOpen, participant: participant, onClose: setIsInviteModalClose }), _jsx("div", { className: 'flex flex-col gap-8', children: _jsx(ListInformation, { dataTestId: 'participant-details-list-information', items: detailItems, useDefaultEmptyDescription: true }) })] }));
};
