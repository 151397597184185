import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QueryClientContext } from '@tanstack/react-query';
import { useContext, useMemo, useState } from 'react';
import { FirebaseAppContext } from 'libs.firebase_react';
import { FormSections } from 'libs.nucleus.form_sections';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Input } from 'libs.nucleus.input';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { Select } from 'libs.nucleus.select';
import { Spinner } from 'libs.nucleus.spinner';
import { TextArea } from 'libs.nucleus.text_area';
import { AuthContext } from 'libs.react.auth';
import { useApiClient } from 'libs.react.contexts';
import { useToastNotification } from 'libs.react.hooks';
import { ErrorUtils } from 'libs.react.utils';
import { StudyConfigurationContext } from '../../contexts';
import { useJobChecker } from '../../hooks';
import { generateRandomOrgName } from '../../utils/study';
const DEFAULT_STUDY_DEPLOY_FORM = {
    name: '',
    description: '',
    region: { label: '-select-', value: '' },
    version: { label: '-select-', value: '' },
};
export const StudyDeployModal = ({ onClose, onSave, versions, clusters }) => {
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const { checkJobStatus } = useJobChecker();
    const momClient = useApiClient("mom" /* ApiClientService.MOM */);
    const { logEvent } = useContext(FirebaseAppContext);
    const { studyId } = useContext(StudyConfigurationContext);
    const { entityId } = useContext(AuthContext);
    const queryClient = useContext(QueryClientContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isPristine, setIsPristine] = useState(true);
    const [studyDeployForm, setStudyDeployForm] = useState(DEFAULT_STUDY_DEPLOY_FORM);
    const saveDisabled = isPristine || !studyDeployForm.name || !studyDeployForm.version.value || !studyDeployForm.region.value;
    const regionOptions = useMemo(() => Object.entries(clusters).reduce((acc, [key, value]) => {
        acc.push({ label: `${value.region.toUpperCase()}`, value: key });
        return acc;
    }, []), [clusters]);
    const versionOptions = useMemo(() => versions.map((version) => ({
        label: translate(`${version.metadata.name} (${version.externalVersion})`),
        value: version.id,
    })), [versions]);
    const orgCode = useMemo(() => {
        const env = studyDeployForm.region.value;
        return generateRandomOrgName(env);
    }, [studyDeployForm.region.value]);
    const saveProdEnv = async () => {
        if (saveDisabled) {
            return;
        }
        try {
            setIsLoading(true);
            let endpoint = `/v1/entities/${entityId}/studies/${studyId}/deploy`;
            const options = {
                studyConfigId: studyDeployForm.version.value,
                metadata: {
                    description: studyDeployForm.description,
                    name: studyDeployForm.name,
                },
            };
            endpoint += '?autoProvision=true';
            options.cortexEnv = orgCode;
            const { data: response } = await momClient.post(endpoint, options);
            checkJobStatus(response.data.jobId, {
                interval: 3000,
                onInterval: (jobId, response, endPolling) => handleEnvironmentCreation(jobId, response, endPolling),
            });
            logEvent('prod_env_created', {
                study_prod_id: studyId,
                study_prod_name: studyDeployForm.name,
                study_prod_version: studyDeployForm.version.label,
                study_prod_description: studyDeployForm.description,
            });
        }
        catch (error) {
            console.error('Error deploying study configuration', error);
            onDeployError();
        }
    };
    const invalidateEnvironments = async () => {
        await queryClient?.invalidateQueries({
            queryKey: ['entities', entityId, 'studies', studyId, 'environments', 'prod'],
        });
        await queryClient?.invalidateQueries({ queryKey: ['entities', entityId, 'clusters'] });
    };
    /**
     * We validate the job til the environment is created, after that is when the deployment starts
     */
    const handleEnvironmentCreation = (jobId, response, endPolling) => {
        const operationType = 'environment.create';
        const deployedOrgCode = orgCode;
        const environmentJob = response.operations.find((operation) => operation.type === operationType);
        if (environmentJob && environmentJob.status === 'completed') {
            onSave(jobId, deployedOrgCode);
            invalidateEnvironments();
            endPolling();
            addNotification({
                title: translate('Production version is being deployed. Hang tight, this might take a bit! You can navigate away from this page'),
                type: 'info',
            });
        }
        if (response.status === 'failed') {
            onDeployError(ErrorUtils.getResultErrorMessage(response.result));
            endPolling();
            logEvent('study_prod_deploy_failed', { study_id: studyId });
        }
    };
    const onDeployError = (message) => {
        const subtitle = message
            ? translate('The deployment of this version of your study failed because of "{message}"', { message })
            : translate('The deployment of this version of your study failed. Please try again.');
        addNotification({ title: translate('Error deploying study'), subtitle, type: 'error' });
        setIsLoading(false);
    };
    const savePrimaryButton = {
        label: translate('Create'),
        onClick: saveProdEnv,
        disabled: saveDisabled,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onClose,
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            saveProdEnv();
        }
    };
    const onSubmit = (event) => {
        event.preventDefault();
        saveProdEnv();
    };
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setStudyDeployForm((oldForm) => ({ ...oldForm, [id]: value }));
        setIsPristine(false);
    };
    const handleRegionChange = (newRegion) => {
        setStudyDeployForm((oldForm) => ({ ...oldForm, region: newRegion }));
        setIsPristine(false);
    };
    const handleVersionChange = (newVersion) => {
        setStudyDeployForm((oldForm) => ({ ...oldForm, version: newVersion }));
        setIsPristine(false);
    };
    const formSections = [
        {
            title: translate('Details'),
            content: (_jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx(Input, { id: 'name', label: translate('Production environment name'), maxLength: 50, onChange: handleInputChange, required: true, value: studyDeployForm.name, width: 'lg', autoComplete: 'off' }), _jsx(TextArea, { id: 'description', label: translate('Description'), onChange: handleInputChange, value: studyDeployForm.description, width: 'lg' })] })),
        },
        {
            title: translate('Configuration'),
            content: (_jsxs("div", { className: 'flex flex-col gap-6 w-[27.5rem]', children: [_jsx(Select, { dataTestId: 'create-env-region', label: translate('Region'), description: translate('Select the region where you want to deploy the production environment'), onChange: handleRegionChange, options: regionOptions, required: true, value: studyDeployForm.region, width: 'lg', disabled: !regionOptions.length }), _jsx(Select, { dataTestId: 'create-env-version', label: translate('Study build version'), description: translate('Select a version of your study build to put in your production environment. You can only select a version that has been previously tested in a sandbox first.'), onChange: handleVersionChange, options: versionOptions, required: true, value: studyDeployForm.version, width: 'lg', disabled: !versionOptions.length })] })),
        },
    ];
    return (_jsxs(ModalWindow, { title: translate('Create environment'), isOpen: true, closeWindow: onClose, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], width: 'full', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), _jsx("form", { className: 'min-h-[30rem]', autoComplete: 'off', onSubmit: onSubmit, onKeyUp: handleEnterPress, children: _jsx(FormSections, { sections: formSections }) })] }));
};
