import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { FirebaseAuthContext } from 'libs.firebase_react';
import { useLocalization } from 'libs.localization.react';
import { useApiClient } from 'libs.react.contexts';
export const useBundleLoader = (activity, locale, version) => {
    const { localization, isScreenReportReady } = useLocalization({ activity });
    const { get } = useApiClient("cloud" /* ApiClientService.CLOUD */);
    const { firebaseToken } = useContext(FirebaseAuthContext);
    const versionPair = version ? `&version=${version}` : '';
    return useQuery({
        queryKey: ['translation', 'bundle', activity.short_name, locale, version],
        queryFn: () => {
            return get(`/translation/bundle?id=${localization?.getId()}&locale=${locale}${versionPair}`, {
                responseType: 'blob',
                // Pass firebase token, don't pass medable token
                withCredentials: false,
                headers: {
                    Authorization: `Bearer ${firebaseToken}`,
                },
            });
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: false,
        enabled: locale !== undefined && activity.short_name !== undefined && isScreenReportReady(locale) && !!firebaseToken,
    });
};
export default useBundleLoader;
