import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Pill } from 'libs.nucleus.pill';
import { DeploymentUtils } from '../../utils/deployment';
export const StudyDeploymentStatus = ({ studyToDeploy }) => {
    const translate = useLocalizeMessage();
    const statusMap = useMemo(() => {
        return {
            active: { iconColor: 'yellow', label: translate('Running') },
            completed: { iconColor: 'green', label: translate('Success') },
            expired: { iconColor: 'gray', label: translate('Expired') },
            failed: { iconColor: 'red', label: translate('Failed') },
        };
    }, []);
    return useMemo(() => {
        const deploymentStatus = DeploymentUtils.getStatus(studyToDeploy);
        const { label, iconColor } = statusMap[deploymentStatus] || statusMap.expired;
        return _jsx(Pill, { label: label, iconBackgroundColor: iconColor, variant: 'status' });
    }, [translate, statusMap]);
};
