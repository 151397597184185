import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useContext, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { StudyLocalizationStatus } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { AssetTypes } from 'libs.localization.common';
import { useLocalization } from 'libs.localization.react';
import { Locale, useLocalizeMessage } from 'libs.nucleus.i18n';
import { Text } from 'libs.nucleus.typography';
import { FileInfoBox, PageHeader } from 'libs.react.components';
import { AssetsInfoPanel, LocaleStatusProgress } from '../../components';
import { TranslationWorkbenchContext } from '../../contexts';
import { useLibraryDetails, useTranslationDetails } from '../../hooks';
import { toSentenceCase } from '../../utils/string_utils';
export const ResourcePdfReviewView = ({ assetId, studyId }) => {
    const t = useLocalizeMessage();
    const [queryParams] = useSearchParams();
    const baseLocale = Locale.en_US;
    const { locale = baseLocale } = useParams();
    const assetType = queryParams.get('assetType');
    const { logEvent } = useContext(FirebaseAppContext);
    const { studyLocalizationStatus, resourcesAssets } = useContext(TranslationWorkbenchContext);
    const resource = Object.keys(resourcesAssets).find((key) => resourcesAssets[key].id === assetId);
    const isStudyinDraft = studyLocalizationStatus !== StudyLocalizationStatus.READY;
    const asset = {
        id: assetId,
        type: assetType,
        version: '1', // @TODO figure out version
    };
    const isCompareMode = locale !== baseLocale;
    const { localizationEntry } = useLocalization(asset);
    const baseLocaleResource = localizationEntry?.localization_resources?.[baseLocale]?.study_pdf?.resourceId;
    const localeResource = localizationEntry?.localization_resources?.[locale]?.study_pdf?.resourceId;
    const { data: baseLocaleData } = useLibraryDetails(baseLocaleResource, 'asset', true);
    const { data: localeData } = useTranslationDetails(baseLocaleResource, localeResource, isCompareMode);
    const localeVersion = localizationEntry?.supported_locales?.[locale]?.version;
    const basePdfUrl = baseLocaleData?.data.data.blobUrl;
    const localePdfUrl = localeData?.data.data.blobUrl;
    const breadcrumbPages = useMemo(() => [
        { name: t('Translations'), path: `/studies/${studyId}/build/translations` },
        {
            name: toSentenceCase(resource) || '',
            path: `/studies/${studyId}/build/translations/${assetId}?assetType=${assetType}`,
        },
        { name: locale || '', path: `/studies/${studyId}/build/translations/${assetId}/review/${locale}` },
    ], [assetId, assetType]);
    const fileName = isCompareMode ? localeData?.data.data.data.name : baseLocaleData?.data.data.name;
    const info = [
        { title: t('Resource Name'), description: toSentenceCase(resource) },
        {
            title: t('Type'),
            description: resource ? resourcesAssets[resource].type : '',
        },
        {
            title: t('Content type'),
            description: 'File Upload',
        },
        {
            title: t('Content'),
            description: fileName && (_jsx("div", { className: 'flex flex-wrap gap-4', children: _jsx(FileInfoBox, { name: fileName }) })),
        },
    ];
    // @TODO: Revisit this
    const handleExport = useCallback(async () => {
        if (localePdfUrl) {
            window.open(localePdfUrl, '_blank', 'noreferrer');
            logEvent('translations_screen_report_downloaded', {
                context: 'study',
                type: AssetTypes.RESOURCES_PDF,
                id: assetId,
                locale,
            });
        }
    }, [localePdfUrl]);
    const headerOptions = [
        { name: t('Export'), onClick: () => handleExport(), variant: 'primary', disabled: isStudyinDraft },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, { title: locale, breadcrumbPages: breadcrumbPages, options: headerOptions, customOptions: locale !== Locale.en_US && resource ? (_jsx(LocaleStatusProgress, { asset: asset, locale: locale, assetId: assetId, isDisabled: isStudyinDraft, trackingContext: { context: 'study', type: assetType } })) : (_jsx("span", {})) }), _jsx("hr", { className: 'mb-6' }), _jsx("div", { className: 'flex flex-col gap-6', children: _jsx(AssetsInfoPanel, { listItems: info, locale: Locale.en_US, title: t('Information'), version: localeVersion }) }), _jsxs("div", { className: 'mt-8', children: [isCompareMode && (_jsxs(Text, { className: 'mb-2', children: [baseLocale, " ", t('compared to'), " ", locale] })), _jsxs("div", { className: 'flex gap-6 w-full ', children: [_jsx("div", { className: 'flex-1 aspect-[1/1.41]', children: basePdfUrl && (_jsx("embed", { src: `${basePdfUrl}#toolbar=0&&view=FitH`, type: 'application/pdf', width: '100%', height: '100%' })) }), isCompareMode && localePdfUrl && (_jsx("div", { className: 'flex-1 aspect-[1/1.41]', children: _jsx("embed", { src: `${localePdfUrl}#toolbar=0&view=FitH`, type: 'application/pdf', width: '100%', height: '100%' }) }))] })] })] }));
};
