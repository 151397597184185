export var EnvironmentCluster;
(function (EnvironmentCluster) {
    EnvironmentCluster["EDGE"] = "edge";
    EnvironmentCluster["DEV"] = "dev";
    EnvironmentCluster["EPHEMERAL"] = "ephemeral";
    EnvironmentCluster["INT_DEV"] = "int-dev";
    EnvironmentCluster["LOCAL"] = "local";
    EnvironmentCluster["QA"] = "qa";
    EnvironmentCluster["PROD"] = "prod";
})(EnvironmentCluster || (EnvironmentCluster = {}));
