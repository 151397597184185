import { EndingConditionType, StartingConditionType } from './activity_dependency.types';
export const DISABLED_STARTING_MAP = {
    [StartingConditionType.VISIT_CONFIRMATION]: {
        afterActivityCompletion: false,
        activityResponse: false,
        dateResponse: true,
        visitConfirmation: true,
        studyCreation: false,
    },
    [StartingConditionType.AFTER_ACTIVITY_COMPLETION]: {
        afterActivityCompletion: true,
        activityResponse: false,
        dateResponse: false,
        visitConfirmation: false,
        studyCreation: true,
    },
    [StartingConditionType.ACTIVITY_RESPONSE]: {
        afterActivityCompletion: false,
        activityResponse: true,
        dateResponse: false,
        visitConfirmation: false,
        studyCreation: false,
    },
    [StartingConditionType.DATE_RESPONSE]: {
        afterActivityCompletion: false,
        activityResponse: false,
        dateResponse: true,
        visitConfirmation: true,
        studyCreation: false,
    },
    [StartingConditionType.STUDY_CREATION]: {
        afterActivityCompletion: true,
        activityResponse: true,
        dateResponse: true,
        visitConfirmation: true,
        studyCreation: true,
    },
};
export const DISABLED_ENDING_MAP = {
    [EndingConditionType.END_OF_DAY]: {
        endOfDay: true,
        afterActivityCompletion: false,
        activityResponse: false,
        dateResponse: true,
        uponCompletion: true,
        afterDuration: true,
        visitConfirmation: true,
        endless: true,
        numOcurrences: true,
    },
    [EndingConditionType.UPON_COMPLETION]: {
        endOfDay: true,
        afterActivityCompletion: true,
        activityResponse: true,
        dateResponse: true,
        uponCompletion: true,
        afterDuration: true,
        visitConfirmation: true,
        endless: true,
        numOcurrences: true,
    },
    [EndingConditionType.AFTER_ACTIVITY_COMPLETION]: {
        endOfDay: false,
        afterActivityCompletion: true,
        activityResponse: true,
        dateResponse: true,
        uponCompletion: false,
        afterDuration: false,
        visitConfirmation: false,
        endless: true,
        numOcurrences: true,
    },
    [EndingConditionType.ACTIVITY_RESPONSE]: {
        endOfDay: false,
        afterActivityCompletion: true,
        activityResponse: true,
        dateResponse: false,
        uponCompletion: false,
        afterDuration: false,
        visitConfirmation: false,
        endless: true,
        numOcurrences: true,
    },
    [EndingConditionType.DATE_RESPONSE]: {
        endOfDay: true,
        afterActivityCompletion: false,
        activityResponse: false,
        dateResponse: true,
        uponCompletion: true,
        afterDuration: true,
        visitConfirmation: true,
        endless: true,
        numOcurrences: true,
    },
    [EndingConditionType.AFTER_DURATION]: {
        endOfDay: true,
        afterActivityCompletion: false,
        activityResponse: false,
        dateResponse: true,
        uponCompletion: true,
        afterDuration: true,
        visitConfirmation: true,
        endless: true,
        numOcurrences: true,
    },
    [EndingConditionType.VISIT_CONFIRMATION]: {
        endOfDay: true,
        afterActivityCompletion: false,
        activityResponse: false,
        dateResponse: true,
        uponCompletion: true,
        afterDuration: true,
        visitConfirmation: true,
        endless: true,
        numOcurrences: true,
    },
    [EndingConditionType.ENDLESS]: {
        endOfDay: true,
        afterActivityCompletion: true,
        activityResponse: true,
        dateResponse: true,
        uponCompletion: true,
        afterDuration: true,
        visitConfirmation: true,
        endless: true,
        numOcurrences: true,
    },
    [EndingConditionType.NUM_OF_OCURRENCES]: {
        endOfDay: true,
        afterActivityCompletion: false,
        activityResponse: false,
        dateResponse: true,
        uponCompletion: false,
        afterDuration: false,
        visitConfirmation: false,
        endless: true,
        numOcurrences: true,
    },
};
export const DISABLED_RECURRENCE_MAP = {
    [EndingConditionType.AFTER_ACTIVITY_COMPLETION]: {
        endOfDay: true,
        afterActivityCompletion: true,
        activityResponse: false,
        dateResponse: true,
        uponCompletion: true,
        afterDuration: true,
        visitConfirmation: false,
        endless: true,
        numOcurrences: false,
    },
    [EndingConditionType.ACTIVITY_RESPONSE]: {
        endOfDay: true,
        afterActivityCompletion: false,
        activityResponse: true,
        dateResponse: true,
        uponCompletion: true,
        afterDuration: true,
        visitConfirmation: false,
        endless: true,
        numOcurrences: false,
    },
    [EndingConditionType.VISIT_CONFIRMATION]: {
        endOfDay: true,
        afterActivityCompletion: false,
        activityResponse: false,
        dateResponse: true,
        uponCompletion: true,
        afterDuration: true,
        visitConfirmation: true,
        endless: true,
        numOcurrences: false,
    },
    [EndingConditionType.ENDLESS]: {
        endOfDay: true,
        afterActivityCompletion: true,
        activityResponse: true,
        dateResponse: true,
        uponCompletion: true,
        afterDuration: true,
        visitConfirmation: true,
        endless: true,
        numOcurrences: true,
    },
    [EndingConditionType.NUM_OF_OCURRENCES]: {
        endOfDay: true,
        afterActivityCompletion: false,
        activityResponse: false,
        dateResponse: true,
        uponCompletion: true,
        afterDuration: true,
        visitConfirmation: false,
        endless: true,
        numOcurrences: true,
    },
    [EndingConditionType.END_OF_DAY]: {
        endOfDay: true,
        afterActivityCompletion: false,
        activityResponse: false,
        dateResponse: true,
        uponCompletion: true,
        afterDuration: true,
        visitConfirmation: true,
        endless: true,
        numOcurrences: true,
    },
    [EndingConditionType.UPON_COMPLETION]: {
        endOfDay: true,
        afterActivityCompletion: false,
        activityResponse: false,
        dateResponse: true,
        uponCompletion: true,
        afterDuration: true,
        visitConfirmation: true,
        endless: true,
        numOcurrences: true,
    },
    [EndingConditionType.DATE_RESPONSE]: {
        endOfDay: true,
        afterActivityCompletion: false,
        activityResponse: false,
        dateResponse: true,
        uponCompletion: true,
        afterDuration: true,
        visitConfirmation: true,
        endless: true,
        numOcurrences: true,
    },
    [EndingConditionType.AFTER_DURATION]: {
        endOfDay: true,
        afterActivityCompletion: false,
        activityResponse: false,
        dateResponse: true,
        uponCompletion: true,
        afterDuration: true,
        visitConfirmation: true,
        endless: true,
        numOcurrences: true,
    },
};
