import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useMatch } from 'react-router-dom';
import { useLocalizeMessage, LocaleContext } from 'libs.nucleus.i18n';
import { Spinner } from 'libs.nucleus.spinner';
import { SectionCard } from 'libs.react.components';
import { SiteContext } from '../../contexts/site';
import { getApiInstance } from '../../helpers/api.helpers';
import { formatVisitWindow } from '../../helpers/participants.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { Card } from '../card';
export const ParticipantVisits = () => {
    const translate = useLocalizeMessage();
    const currentLocale = useContext(LocaleContext);
    const { environment } = useCurrentEnvironment();
    const { site } = useContext(SiteContext);
    const match = useMatch('/site/study/:studyId/participants/:participantId');
    const participantId = match?.params.participantId;
    const getParticipantVisitsData = async () => {
        if (!environment) {
            return [];
        }
        try {
            const response = await getApiInstance(environment).get(`/v2/routes/c_sites/${site?._id}/c_public_users/${participantId}/c_visit_schedule`);
            return response?.data.data || {};
        }
        catch (error) {
            return {};
        }
    };
    const { data: participantVisits, isLoading } = useQuery({
        queryKey: ['participantVisits', participantId],
        queryFn: getParticipantVisitsData,
        enabled: true,
    });
    if (!participantVisits || isLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    if (participantVisits.length === 0) {
        return (_jsx(SectionCard, { children: _jsx("section", { className: 'flex flex-col items-center justify-center p-10 m-10', children: _jsx("p", { className: 'mb-8 text-sm text-secondary', children: translate('No visits have been created') }) }) }));
    }
    return (_jsx("div", { className: 'flex flex-col gap-8', children: participantVisits.map((visit, index) => {
            return (_jsx(Card, { children: _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'flex gap-4', children: _jsx("div", { "data-testid": 'visit-name', children: visit.c_name }) }), _jsxs("div", { className: 'flex gap-2', children: [_jsx("span", { className: 'text-gray-500', "data-testid": `visit-period-${index}`, children: formatVisitWindow({
                                        windowStartDate: visit.c_window_start_date,
                                        windowEndDate: visit.c_window_end_date,
                                        currentLocale,
                                    }) }), _jsx("span", { children: "\u2022" }), _jsx("span", { className: 'text-gray-500', "data-testid": `visit-assessments-${index}`, children: translate('{assignments} assessments', { assignments: visit.assignments }) })] })] }, visit.c_visit) }, visit.c_visit));
        }) }));
};
