import { jsx as _jsx } from "react/jsx-runtime";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { FORMAT_ELEMENT_COMMAND, $selectAll, $getNodeByKey } from 'lexical';
import { useEffect } from 'react';
import { Dropdown } from 'libs.nucleus.dropdown';
import { Icon } from 'libs.nucleus.icon';
import { FormatterWrapper } from './formatter_wrapper';
import { AlignmentOptions, useAligmentContext, useSetAlignmentContext } from '../contexts/alignment_context';
import { CustomListItemNode, CustomParagraphNode } from '../nodes';
const alignContentActions = [
    { type: AlignmentOptions.LEFT, icon: 'alignLeft', label: 'Align left' },
    { type: AlignmentOptions.CENTER, icon: 'alignCenter', label: 'Align center' },
    { type: AlignmentOptions.RIGHT, icon: 'alignRight', label: 'Align right' },
];
export const AlignContent = ({ onClick }) => {
    const [editor] = useLexicalComposerContext();
    const alignment = useAligmentContext();
    const setAlignment = useSetAlignmentContext();
    useEffect(() => {
        // For aligning the content, select all and apply the alignment
        return editor.registerCommand(FORMAT_ELEMENT_COMMAND, (alignment) => {
            setAlignment(alignment);
            $selectAll();
            return false;
        }, 1);
    }, [editor]);
    useEffect(() => {
        const listeners = [
            editor.registerMutationListener(CustomListItemNode, (mutatedNodes) => {
                editor.update(() => {
                    for (const [nodeKey, mutation] of mutatedNodes) {
                        if (mutation === 'created') {
                            const createdNode = $getNodeByKey(nodeKey);
                            if (createdNode instanceof CustomListItemNode && !createdNode.hasFormat(alignment)) {
                                createdNode.setFormat(alignment);
                            }
                        }
                    }
                });
            }),
            editor.registerMutationListener(CustomParagraphNode, (mutatedNodes) => {
                editor.update(() => {
                    for (const [nodeKey, mutation] of mutatedNodes) {
                        if (mutation === 'created') {
                            const createdNode = $getNodeByKey(nodeKey);
                            if (createdNode instanceof CustomParagraphNode && !createdNode.hasFormat(alignment)) {
                                createdNode.setFormat(alignment);
                            }
                        }
                    }
                });
            }),
        ];
        return () => listeners.forEach((l) => l());
    }, [editor, alignment]);
    return (_jsx(FormatterWrapper, { children: _jsx(Dropdown, { variant: 'icon', iconName: 'formatAlignList', iconSize: 20, label: 'Format alignment', items: alignContentActions.map((action) => ({
                id: action.type,
                content: _jsx(Icon, { name: action.icon, color: 'black' }),
                onClick: () => {
                    onClick && onClick(action.type);
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, action.type);
                },
                ariaLabel: action.label,
            })), "aria-label": `Format alignment` }, 'align') }));
};
AlignContent.displayName = 'AlignContent';
