import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Tabs } from 'libs.nucleus.tabs';
import { PageHeader } from 'libs.react.components';
import { useTabNavigation } from 'libs.react.hooks';
import { ScheduleTimelineScheduledActivities } from './schedule_timeline_scheduled_activities';
import { StudyConfigurationContext } from '../../contexts';
import { timelineNameToHash } from '../../utils/study';
export const ScheduleTimeline = () => {
    const translate = useLocalizeMessage();
    const { setOnboarding, currentStudy, persistedTimelineId } = useContext(StudyConfigurationContext);
    const timelines = currentStudy.getScheduleTimelines();
    const timelineName = timelines[persistedTimelineId]?.name ?? timelines.main.name;
    const onboardingName = timelines.onboarding.name;
    const [activeTab, setActiveTab] = useTabNavigation([
        timelineNameToHash(onboardingName),
        timelineNameToHash(timelineName),
    ]);
    const tabLabels = [translate('Onboarding'), translate('Schedule')];
    const tabContent = [
        _jsx(ScheduleTimelineScheduledActivities, { isOnboarding: true }, 'study-details'),
        _jsx(ScheduleTimelineScheduledActivities, {}, 'visit-activities'),
    ];
    useEffect(() => {
        setOnboarding(activeTab === 0);
    }, []);
    const onTabChange = (newActiveTab) => {
        setOnboarding(newActiveTab === 0);
        setActiveTab(newActiveTab);
    };
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, { title: translate('Schedule Timeline'), subtitle: translate('Build the schedule of activities for your trial here'), size: 'sm' }), _jsx(Tabs, { tabLabels: tabLabels, tabContent: tabContent, defaultIndex: activeTab, onTabChange: onTabChange })] }));
};
