import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useContext, useState } from 'react';
import { StudyLocalizationStatus } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { LocalizationStatus } from 'libs.localization.common';
import { useLocalizationCollection } from 'libs.localization.react';
import { Button } from 'libs.nucleus.button';
import { Locale, useLocalizeMessage } from 'libs.nucleus.i18n';
import { useBooleanState } from 'libs.react.hooks';
import { StudyTranslationStatus } from './study_translation_status.component';
import { StudyTranslationStatusModal } from './study_translation_status_modal.component';
import { TranslationWorkbenchContext } from '../../contexts';
export const StudyTranslationStatusWorkflow = ({ study, updateStudy }) => {
    const t = useLocalizeMessage();
    const [isModalOpen, openModal, closeModal] = useBooleanState(false);
    const [isProcessRunning, setIsProcessRunning] = useState(false);
    const { localizationStatus: studyLocalizationStatus = StudyLocalizationStatus.NOT_STARTED } = study || {};
    const { resourcesAssets, studyAssets, studyId, notificationsAssets } = useContext(TranslationWorkbenchContext);
    const { logEvent } = useContext(FirebaseAppContext);
    const assets = { ...resourcesAssets, ...studyAssets, ...notificationsAssets };
    const assetData = Object.values(assets)
        .filter((asset) => Boolean(asset.asset))
        .map((asset) => asset.asset);
    const { localizations } = useLocalizationCollection(assetData);
    const handleStatusChange = useCallback(async () => {
        setIsProcessRunning(true);
        if ((studyLocalizationStatus === StudyLocalizationStatus.DRAFT ||
            studyLocalizationStatus === StudyLocalizationStatus.NOT_STARTED) &&
            assets) {
            const assetPromises = localizations?.map(async (localization, index) => {
                const asset = assetData[index];
                if (asset.messages) {
                    await localization.setMessages(Locale.en_US, asset.messages);
                    await localization.setStatus(Locale.en_US, LocalizationStatus.READY);
                    if (asset.preview) {
                        await localization.setPreview(asset.preview);
                    }
                }
            }) || [];
            await Promise.all(assetPromises).catch((error) => {
                // @TODO: Add error handling
                console.error('Error while changing status', error);
            });
            logEvent('translations_study_localization_status_change', {
                studyId,
                status: StudyLocalizationStatus.READY,
                previousStatus: studyLocalizationStatus,
            });
            study.setLocalizationStatus(StudyLocalizationStatus.READY);
        }
        if (studyLocalizationStatus === StudyLocalizationStatus.READY) {
            logEvent('translations_study_localization_status_change', {
                studyId,
                status: StudyLocalizationStatus.DRAFT,
                previousStatus: studyLocalizationStatus,
            });
            study.setLocalizationStatus(StudyLocalizationStatus.DRAFT);
        }
        updateStudy(study);
        setIsProcessRunning(false);
        closeModal();
    }, [localizations, assetData, assets]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: 'flex gap-4 items-center', children: [_jsx(StudyTranslationStatus, {}), _jsx(Button, { label: t('Change status'), onClick: openModal, variant: 'secondary' })] }), isModalOpen && (_jsx(StudyTranslationStatusModal, { onModalClose: closeModal, onHandleStatusChange: handleStatusChange, isLoading: isProcessRunning, currentStatus: studyLocalizationStatus }))] }));
};
