import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, useMemo } from 'react';
import { AuthContext } from 'libs.react.auth';
import { useApiClient } from 'libs.react.contexts';
export const EnvironmentsContext = createContext({
    isLoading: true,
    environments: [],
});
export const EnvironmentsProvider = ({ children }) => {
    const { entityId, user } = useContext(AuthContext);
    const momClient = useApiClient("mom" /* ApiClientService.MOM */);
    const fetchEnvironments = async () => {
        const { data: response } = await momClient.get(`/v1/entities/${entityId}/environments`);
        return response?.data || [];
    };
    const { data: environments, isLoading } = useQuery({
        queryKey: ['entities', entityId, 'environments'],
        queryFn: fetchEnvironments,
        enabled: !!user && !!entityId,
    });
    const value = useMemo(() => ({
        isLoading,
        environments,
    }), [isLoading, environments]);
    return _jsx(EnvironmentsContext.Provider, { value: value, children: children });
};
