const matchEnvAndCloudFromHostname = (cortexUrl) => {
    const hostname = cortexUrl?.replace('https://', '');
    // Ensure all possible combinations of the environment and cloud domain are matched.
    const regexString = `^api([.-]?)(?<environment>[a-zA-Z0-9-.]+)?.medable.(?<cloudDomain>(com|cn|tech))`;
    const regex = new RegExp(regexString);
    return hostname.match(regex);
};
export const getMoaBaseUrl = (cortexUrl) => {
    const matchResult = matchEnvAndCloudFromHostname(cortexUrl);
    const { environment, cloudDomain } = matchResult?.groups || {};
    if (environment && environment.includes('eu')) {
        return 'api-eu1.medable.com';
    }
    if (cloudDomain === 'cn') {
        return 'api.medable.cn';
    }
    return 'api.medable.com';
};
export const getFinalUrl = (baseURL, encoded) => {
    return `https://${baseURL}/moa/routes/moa/gen/${encoded}`;
};
export const getStudyShortCode = async (cortexUrl, orgCode) => {
    const moaBaseUrl = getMoaBaseUrl(cortexUrl);
    const cortexHostname = cortexUrl.replace('https://', '');
    const encoded = encodeURIComponent(`${cortexHostname}/${orgCode}`);
    const fetchResponse = await fetch(getFinalUrl(moaBaseUrl, encoded));
    const response = await fetchResponse.json();
    return response?.data;
};
