import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { LocaleContext, useLocalizeMessage } from 'libs.nucleus.i18n';
import { InlineNotification } from 'libs.nucleus.notification';
import { Select } from 'libs.nucleus.select';
import { formatLanguageOptions } from '../../../helpers/locales.helpers';
import { useSiteContext } from '../../../hooks/use_site_context';
export const ParticipantLanguageField = ({ form, errors, onSelectFieldChange, isFieldtouched, }) => {
    const translate = useLocalizeMessage();
    const site = useSiteContext();
    const currentLocale = useContext(LocaleContext);
    const localesList = formatLanguageOptions(site?.c_supported_locales ?? [], currentLocale);
    useEffect(() => {
        if (localesList.length === 1) {
            onSelectFieldChange(localesList[0], 'language');
        }
    }, []);
    if (localesList.length === 0) {
        return (_jsxs(_Fragment, { children: [_jsx("label", { className: 'nucleus-order-none nucleus-max-w-full nucleus-flex-none nucleus-grow-0 nucleus-text-sm nucleus-font-medium nucleus-text-text-primary', children: translate('Language') }), _jsx(InlineNotification, { subtitle: translate('Participant locales are not configured for this site, please contact an administrator for help.'), title: translate('Participant locales not configured'), type: 'warning' })] }));
    }
    return (_jsx(Select, { required: true, dataTestId: 'participant-locale-select', errorMessage: errors?.language && isFieldtouched('language') ? errors?.language : undefined, hasError: !!(errors?.language && isFieldtouched('language')), label: translate('Language'), onChange: (value) => {
            onSelectFieldChange(value, 'language');
        }, options: localesList, value: form?.language, width: 'lg' }));
};
