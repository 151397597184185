import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Locale } from 'libs.i18n-core';
import { useLocalization } from 'libs.localization.react';
import { TranslationScreen } from './translation_screen.component';
import useBundleLoader from '../../hooks/use_bundle_loader';
import useBundlePreview from '../../hooks/use_bundle_preview';
export const ActivityReviewScreenList = ({ activity, localeVersion }) => {
    const baseLocale = Locale.en_US;
    const { locale } = useParams();
    const { data: baseBundle, isFetching: isBaseBundleFetching, isError: isBaseBundleError, } = useBundleLoader(activity, baseLocale);
    const { bundlePreview: basePreview } = useBundlePreview(baseBundle?.data);
    const { isScreenReportReady } = useLocalization({ activity });
    const isCompareMode = baseLocale !== locale;
    const { data: localeBundle, isFetching: isLocaleBundleFetching, isError: isLocaleBundleError, } = useBundleLoader(activity, locale, localeVersion);
    const { bundlePreview: localePreview } = useBundlePreview(localeBundle?.data);
    const screens = useMemo(() => {
        // filter screens without type = 'completion' and 'review'
        return activity.screens.filter((screen) => screen.type !== 'completion' && screen.type !== 'review');
    }, [activity]);
    return screens.map((screen, index) => (_jsxs("div", { className: 'mt-8', children: [_jsxs("div", { className: 'mb-2', children: [`${index + 1}. ${screen.name || ''} ${baseLocale}`, isCompareMode && ` compared to ${locale}`] }), _jsx(TranslationScreen, { screen: screen, basePreview: basePreview, localePreview: localePreview, isBasePreviewLoading: !isScreenReportReady(String(locale)) || isBaseBundleFetching, isLocalePreviewLoading: !isScreenReportReady(String(locale)) || isLocaleBundleFetching, isBaseBundleError: isBaseBundleError, isLocaleBundleError: isLocaleBundleError }), index < screens.length - 1 && _jsx("hr", {})] }, `${screen.identifier}`)));
};
