import { useQuery } from '@tanstack/react-query';
import { useApiClient } from 'libs.react.contexts';
import { LibraryEndpoint } from 'libs.react.types';
export const useNotificationsLoader = (studyId) => {
    const libraryClient = useApiClient("library" /* ApiClientService.LIBRARY */);
    const getTemplates = async () => {
        const { data: response } = await libraryClient.get(LibraryEndpoint.GET_TEMPLATES + `?public=true&studyId=${studyId}`);
        return response.data;
    };
    const { data: templates = [] } = useQuery({
        queryKey: ['templates'],
        queryFn: getTemplates,
    });
    return templates;
};
