import { I18nUtils } from '@medable/i18n-core';
import { getApiInstance } from '../../../helpers/api.helpers';
export const getParticipantFormInitialValues = (participant, currentLocale = 'en-US') => {
    return {
        email: participant?.c_email ?? undefined,
        language: participant?.c_locale
            ? { label: I18nUtils.getLanguageLabel(participant.c_locale, currentLocale, true), value: participant?.c_locale }
            : { label: '', value: '' },
        participantNumber: participant?.c_number ?? undefined,
    };
};
export const shouldDisplayParticipantNumber = (org) => {
    if (org?.c_automatic_participant_id_generation || org?.c_participantid_on_visit) {
        return false;
    }
    return true;
};
export const createParticipant = async ({ environment, org, site, form, }) => {
    const body = {
        c_study: org?._id,
        c_locale: I18nUtils.i18nToCortexFormat(form.language.value),
        ...(!org?.c_no_pii && form?.email && { c_email: form.email }),
        ...(form?.participantNumber && {
            c_number: form.participantNumber,
        }),
    };
    const path = `c_site/${site?._id}/c_subjects`;
    const createdParticipant = await getApiInstance(environment).post(path, body, {
        params: {
            limit: 1,
            skip: 0,
            sort: JSON.stringify({ created: -1 }),
        },
    });
    return createdParticipant?.data?.data[0];
};
export const sendParticipantIniteEmail = async ({ org, site, environment, participant, studyInviteCode, }) => {
    const isUsingPinOnly = org?.c_subject_invite_validation === 'pin_only';
    const isPiiStudy = !org?.c_no_pii;
    if (isUsingPinOnly && isPiiStudy) {
        const defaultGroupId = org?.c_default_subject_group?._id;
        const inviteData = {
            c_site: site._id,
            c_public_user: participant._id,
            c_group: defaultGroupId,
            locale: participant.c_locale,
            c_email: participant.c_email,
            c_study_code: studyInviteCode,
        };
        const inviteResponse = await getApiInstance(environment).get(`/routes/resend_invite`, {
            params: inviteData,
        });
        return inviteResponse?.data;
    }
};
