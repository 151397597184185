import { jsx as _jsx } from "react/jsx-runtime";
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Input } from 'libs.nucleus.input';
import { useOrgContext } from '../../../hooks/use_org_context';
import { shouldDisplayParticipantNumber } from '../helpers/participant_form.helpers';
export const ParticipantNumberField = ({ form, errors, onFieldChange, isFieldtouched }) => {
    const translate = useLocalizeMessage();
    const org = useOrgContext();
    if (org && !shouldDisplayParticipantNumber(org)) {
        return null;
    }
    return (_jsx(Input, { dataTestId: 'participant-number', id: 'participantNumber', name: 'participantNumber', label: translate('Participant Number'), onChange: onFieldChange, value: form?.participantNumber ?? '', width: 'lg', description: org?.c_format_spec_subject_id, errorMessage: errors?.participantNumber && isFieldtouched('participantNumber') ? errors?.participantNumber : undefined, hasError: !!(errors?.participantNumber && isFieldtouched('participantNumber')) }));
};
