import { AssetTypes, LocalizationStatus } from 'libs.localization.common';
import { Locale } from 'libs.nucleus.i18n';
import { useNotificationsLoader } from './use_notifications_loader.hook';
import { AssetRole, StudyAssetType } from '../types';
import { toSnakeCase } from '../utils/string_utils';
function getType(type) {
    switch (type) {
        case 'push':
            return AssetTypes.NOTIFICATION_PUSH_NOTIFICATION;
        case 'sms':
            return AssetTypes.NOTIFICATION_SMS;
        case 'email':
            return AssetTypes.NOTIFICATION_EMAIL;
        default:
            return AssetTypes.NOTIFICATION_PUSH_NOTIFICATION;
    }
}
export const useNotificationsData = (studyId, locales) => {
    const templates = useNotificationsLoader(studyId);
    if (!templates?.length) {
        return {};
    }
    const data = {};
    templates.forEach((template) => {
        const localeData = {};
        for (const locale of locales) {
            localeData[locale] = locale === Locale.en_US ? LocalizationStatus.READY : LocalizationStatus.NOT_STARTED;
        }
        data[template.name] = {
            id: template.id,
            type: StudyAssetType.NOTIFICATIONS,
            path: template.id,
            statuses: localeData,
            role: AssetRole.PARTICIPANT,
            assetType: getType(template.data.type),
            asset: {
                type: getType(template.data.type),
                id: template.id,
                version: '1',
                human_id: toSnakeCase(template.name),
                messages: [
                    {
                        namespace: template.id,
                        messages: ['plain', 'subject', 'html'].reduce((acc, key) => {
                            if (template.data[key]) {
                                acc[key] = template.data[key];
                            }
                            return acc;
                        }, {}),
                    },
                ],
                preview: {
                    engine: 'react',
                    id: getType(template.data.type),
                    default_viewport: 'iosSmall',
                    info: {
                        display_name: template.name,
                        list: [],
                    },
                },
            },
        };
    });
    return data;
};
