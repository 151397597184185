import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy, useContext, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { FlagContext } from 'libs.flags.react';
import { NotFoundPage } from 'libs.react.components';
import { StudyWrapper } from './components';
import { STUDY_MANAGEMENT_FLAGS } from './constants';
import { ChatContextProvider, DeploymentProvider, StudyConfigurationProvider, StudiesProvider, VariablesProvider, } from './contexts';
const ReviewPage = lazy(() => import('./pages/review/review.page'));
const ScheduleTimelinePage = lazy(() => import('./pages/schedule_timeline/schedule_timeline.page'));
const StudiesDashboardPage = lazy(() => import('./pages/studies_dashboard/studies_dashboard.page'));
const StudyBuildPage = lazy(() => import('./pages/study_build/study_build.page'));
const ResourcesDashboardPage = lazy(() => import('./pages/resources/resources_dashboard.page'));
const ResourceViewPage = lazy(() => import('./pages/resources/resource_view.page'));
const TranslationPage = lazy(() => import('./pages/translations/translations.page'));
const TranslationAssetsViewPage = lazy(() => import('./pages/translations/translation_assets_view.page'));
const TranslationAssetsReviewPage = lazy(() => import('./pages/translations/translation_assets_review.page'));
const VersionsPage = lazy(() => import('./pages/versions/versions.page'));
const VersionViewPage = lazy(() => import('./pages/versions/version_view.page'));
const StudyTestsPage = lazy(() => import('./pages/study_tests/study_tests.page'));
const StudyDeployPage = lazy(() => import('./pages/study_deploy/study_deploy.page'));
const StudyTestsViewPage = lazy(() => import('./pages/study_tests/study_test_view.page'));
const StudyDeployViewPage = lazy(() => import('./pages/study_deploy/study_deploy_view.page'));
const NotificationsDashboardPage = lazy(() => import('./pages/study_notifications/study_notifications_dashboard.page'));
const ExtensionsPage = lazy(() => import('./pages/extensions/extensions.page'));
const ExtensionViewPage = lazy(() => import('./pages/extensions/extension_view.page'));
const ProvidersWrapper = ({ children }) => {
    return (_jsx(StudiesProvider, { children: _jsx(StudyConfigurationProvider, { children: _jsx(VariablesProvider, { children: _jsx(DeploymentProvider, { children: _jsx(ChatContextProvider, { children: children }) }) }) }) }));
};
export const StudyManagementApp = () => {
    const flagManager = useContext(FlagContext);
    const gatedRoutes = useMemo(() => {
        const routes = [];
        routes.push(_jsx(Route, { path: 'build/translations', element: _jsx(TranslationPage, {}) }));
        routes.push(_jsx(Route, { path: 'build/translations/:assetId', element: _jsx(TranslationAssetsViewPage, {}) }));
        routes.push(_jsx(Route, { path: 'build/translations/:assetId/review/:locale?', element: _jsx(TranslationAssetsReviewPage, {}) }));
        if (flagManager.getValue(STUDY_MANAGEMENT_FLAGS.review)) {
            routes.push(_jsx(Route, { path: 'build/review', element: _jsx(ReviewPage, {}) }));
        }
        return routes;
    }, [flagManager]);
    return (_jsx(ProvidersWrapper, { children: _jsxs(Routes, { children: [_jsx(Route, { index: true, path: 'dashboard', element: _jsx(StudiesDashboardPage, {}) }), _jsxs(Route, { path: ':studyId', element: _jsx(StudyWrapper, {}), children: [_jsx(Route, { path: 'build', element: _jsx(StudyBuildPage, {}) }), _jsx(Route, { path: 'build/schedule', element: _jsx(ScheduleTimelinePage, {}) }), _jsx(Route, { path: 'build/resources', element: _jsx(ResourcesDashboardPage, {}) }), _jsx(Route, { path: 'build/resources/:resourceId', element: _jsx(ResourceViewPage, {}) }), _jsx(Route, { path: 'versions', element: _jsx(VersionsPage, {}) }), _jsx(Route, { path: 'versions/:versionId', element: _jsx(VersionViewPage, {}) }), _jsx(Route, { path: 'build/notifications', element: _jsx(NotificationsDashboardPage, {}) }), _jsx(Route, { path: 'build/extensions', element: _jsx(ExtensionsPage, {}) }), _jsx(Route, { path: 'build/extensions/:extensionId', element: _jsx(ExtensionViewPage, {}) }), _jsx(Route, { path: 'tests', element: _jsx(StudyTestsPage, {}) }), _jsx(Route, { path: 'tests/:envId', element: _jsx(StudyTestsViewPage, {}) }), _jsx(Route, { path: 'deploy', element: _jsx(StudyDeployPage, {}) }), _jsx(Route, { path: 'deploy/:envId', element: _jsx(StudyDeployViewPage, {}) }), ...gatedRoutes] }), _jsx(Route, { path: '*', element: _jsx(NotFoundPage, {}) })] }) }));
};
export { STUDY_MANAGEMENT_FLAGS } from './constants/developer_flags.constants';
