import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { getApiInstance } from '../../helpers/api.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { LegacyAccountContext } from '../legacy_account/legacy_account.context';
export const OrgContext = createContext({
    isLoading: true,
    org: null,
});
export const OrgProvider = ({ children }) => {
    const match = useMatch('/site/study/:studyId/*');
    const { account } = useContext(LegacyAccountContext);
    const { environment } = useCurrentEnvironment(match?.params?.studyId);
    const getOrg = async () => {
        if (!environment || !account) {
            return;
        }
        const url = `/v2/c_study`;
        const { data: response } = await getApiInstance(environment).get(url);
        return response?.data?.[0] || null;
    };
    const { data: org, isLoading } = useQuery({
        queryKey: ['getOrg'],
        queryFn: getOrg,
        enabled: !!environment && !!account,
    });
    const value = useMemo(() => ({
        isLoading,
        org,
    }), [org]);
    return _jsx(OrgContext.Provider, { value: value, children: children });
};
