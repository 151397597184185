import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { StudyLocalizationStatus } from '@axon/rosetta-sdk';
import { Pill } from 'libs.nucleus.pill';
import { TranslationWorkbenchContext } from '../../contexts';
export const StudyTranslationStatus = ({ showStatus }) => {
    const { studyLocalizationStatus } = useContext(TranslationWorkbenchContext);
    const status = showStatus || studyLocalizationStatus;
    return (_jsx("div", { className: 'capitalize', children: _jsx(Pill, { label: status === StudyLocalizationStatus.NOT_STARTED ? StudyLocalizationStatus.DRAFT : status, variant: 'status', iconBackgroundColor: status === StudyLocalizationStatus.DRAFT || status === StudyLocalizationStatus.NOT_STARTED ? 'red' : 'green' }) }));
};
