import { AssetTypes, LocalizationStatus } from 'libs.localization.common';
import { Locale } from 'libs.nucleus.i18n';
import { getTypeByAvailableTo, getRolebyAvailableTo } from '../utils/data_utils';
import { toSnakeCase } from '../utils/string_utils';
export const useResourcesPdfData = (study, locales) => {
    const config = study.getConfiguration();
    const resources = config.studyMetadata.studyResources;
    if (!resources?.length) {
        return {};
    }
    const data = {};
    resources.forEach(({ id, title, availableTo, files }) => {
        if (files) {
            const localeData = {};
            for (const locale of locales) {
                localeData[locale] = locale === Locale.en_US ? LocalizationStatus.READY : LocalizationStatus.NOT_STARTED;
            }
            data[title.trim()] = {
                id,
                path: id,
                type: getTypeByAvailableTo(availableTo),
                role: getRolebyAvailableTo(availableTo),
                assetType: AssetTypes.RESOURCES_PDF,
                statuses: localeData,
                asset: {
                    type: AssetTypes.RESOURCES_PDF,
                    id,
                    version: '1',
                    human_id: toSnakeCase(title),
                    messages: [
                        {
                            namespace: 'study_pdf',
                            messages: {
                                resourceId: files.map((file) => file.id)[0], // Assuming first file to be en-US as this is uploaded first from resource tab
                            },
                        },
                    ],
                },
            };
        }
    });
    return data;
};
