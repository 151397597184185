import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FlagContext } from 'libs.flags.react';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { useScopeValidator } from 'libs.react.auth';
import { AclScope } from 'libs.react.types';
import { STUDY_MANAGEMENT_FLAGS } from '../../constants';
import { DeploymentContext, StudyConfigurationContext } from '../../contexts';
import { timelineNameToHash } from '../../utils/study';
export const useNavigation = () => {
    const { studyId } = useParams();
    const { currentStudy, currentTimelineId } = useContext(StudyConfigurationContext);
    const translate = useLocalizeMessage();
    const flagManager = useContext(FlagContext);
    const { jobs } = useContext(DeploymentContext);
    const canDeploy = useScopeValidator(AclScope.MOM_ENVIRONMENT_GET);
    const navItems = useMemo(() => {
        const timelines = currentStudy.getScheduleTimelines();
        const scheduleHash = timelineNameToHash(timelines[currentTimelineId]?.name ?? timelines.onboarding.name);
        const items = [
            {
                icon: 'navCube',
                links: [
                    {
                        title: translate('Schedule'),
                        path: `/studies/${studyId}/build/schedule${scheduleHash}`,
                        ignoreFragments: true,
                    },
                    { title: translate('Resources'), path: `/studies/${studyId}/build/resources` },
                    { title: translate('Translations'), path: `/studies/${studyId}/build/translations` },
                    { title: translate('Notifications'), path: `/studies/${studyId}/build/notifications` },
                    { title: translate('Extensions'), path: `/studies/${studyId}/build/extensions` },
                ],
                path: `/studies/${studyId}/build`,
                title: 'Build',
            },
            { title: translate('Versions'), path: `/studies/${studyId}/versions`, icon: 'rectangleStack' },
            { title: translate('Tests'), path: `/studies/${studyId}/tests`, icon: 'window' },
        ];
        if (canDeploy) {
            items.push({ title: translate('Deploy'), path: `/studies/${studyId}/deploy`, icon: 'navDeployment' });
        }
        if (flagManager.getValue(STUDY_MANAGEMENT_FLAGS.review)) {
            items[0].links.push({ title: translate('Review'), path: `/studies/${studyId}/build/review` });
        }
        return items;
    }, [flagManager, studyId, jobs, currentTimelineId]);
    return { navItems };
};
