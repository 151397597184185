import { useMutation } from '@tanstack/react-query';
import { useApiClient } from 'libs.react.contexts';
import { LibraryEndpoint } from 'libs.react.types';
export const useNotificationTemplate = () => {
    const libraryClient = useApiClient("library" /* ApiClientService.LIBRARY */);
    return useMutation({
        mutationKey: ['notification_template', 'localized'],
        mutationFn: ({ payload, templateID }) => {
            return libraryClient.post(`${LibraryEndpoint.GET_TEMPLATES}/${templateID}/translations`, payload, {
                headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' },
            });
        },
        retry: false,
    });
};
