import { Duration } from 'luxon';
import { v4 } from 'uuid';
import { ActivityDependencyTriggerType } from '@axon/rosetta-sdk';
import { EndingConditionType, StartingConditionType, } from './activity_dependency.types';
const convertStartConditions = ({ baseCondition, conditions, isRemoteActivity, }) => {
    const startConditions = [];
    conditions.forEach((condition) => {
        const offset = condition.offset?.value ? Number(condition.offset.value) : 0;
        const id = condition.id || v4();
        switch (condition.option.value) {
            case StartingConditionType.AFTER_ACTIVITY_COMPLETION:
            case StartingConditionType.VISIT_CONFIRMATION:
                startConditions.push({
                    ...baseCondition,
                    dependencyId: condition.activityCompletion.meta?.activityId,
                    dependencyType: 'start',
                    id,
                    offsetAfterDateAnchor: offset,
                    triggerType: ActivityDependencyTriggerType.ACTIVITY_COMPLETION,
                    ...(!isRemoteActivity && { dependencyInstanceId: condition.activityCompletion.value }),
                    // We cover for VC in remote activities
                    ...(condition.visitId && { encounterId: condition.visitId.value ?? '' }),
                });
                break;
            case StartingConditionType.ACTIVITY_RESPONSE:
                startConditions.push({
                    ...baseCondition,
                    comparisonOperator: '==',
                    comparisonValue: condition.activityResponse.response.value,
                    dependencyId: condition.activityResponse.activity.meta?.activityId,
                    dependencyType: 'start',
                    id,
                    triggerScreen: condition.activityResponse.screen.label,
                    triggerScreenKey: condition.activityResponse.screen.value,
                    triggerType: ActivityDependencyTriggerType.SCREEN_RESPONSE,
                    ...(!isRemoteActivity && { dependencyInstanceId: condition.activityResponse.activity.value }),
                });
                break;
            case StartingConditionType.DATE_RESPONSE:
                startConditions.push({
                    ...baseCondition,
                    dependencyId: condition.activityResponse.activity.meta?.activityId,
                    dependencyType: 'start',
                    id,
                    offsetAfterDateAnchor: offset,
                    triggerScreen: condition.activityResponse.screen.label,
                    triggerScreenKey: condition.activityResponse.screen.value,
                    triggerType: ActivityDependencyTriggerType.DATE_SCREEN_RESPONSE,
                    ...(!isRemoteActivity && { dependencyInstanceId: condition.activityResponse.activity.value }),
                });
                break;
            case StartingConditionType.STUDY_CREATION:
                startConditions.push({
                    ...baseCondition,
                    dependencyType: 'start',
                    id,
                    offsetAfterDateAnchor: offset,
                    triggerType: ActivityDependencyTriggerType.STUDY_CREATION,
                });
                break;
        }
    });
    return startConditions;
};
const convertEndConditions = ({ activitySequence, baseCondition, conditions, isRemoteActivity, }) => {
    const endConditions = [];
    conditions.forEach((condition) => {
        const offset = condition.offset?.value ? Number(condition.offset.value) : 0;
        const id = condition.id || v4();
        switch (condition.option.value) {
            case EndingConditionType.AFTER_DURATION:
                if (condition.duration && condition.durationPeriod) {
                    endConditions.push({
                        ...baseCondition,
                        dependencyType: 'end',
                        id,
                        triggerType: ActivityDependencyTriggerType.DURATION,
                        windowDuration: Duration.fromObject({
                            [condition.durationPeriod.value]: condition.duration || 1,
                        }).toISO(),
                    });
                }
                break;
            case EndingConditionType.AFTER_ACTIVITY_COMPLETION:
            case EndingConditionType.VISIT_CONFIRMATION:
                if (condition.activityCompletion) {
                    endConditions.push({
                        ...baseCondition,
                        dependencyId: condition.activityCompletion.meta?.activityId,
                        dependencyType: 'end',
                        id,
                        offsetAfterDateAnchor: offset,
                        triggerType: ActivityDependencyTriggerType.ACTIVITY_COMPLETION,
                        ...(!isRemoteActivity && { dependencyInstanceId: condition.activityCompletion.value }),
                        // We cover for VC in remote activities
                        ...(condition.visitId && { encounterId: condition.visitId.value ?? '' }),
                    });
                }
                break;
            case EndingConditionType.UPON_COMPLETION:
                endConditions.push({
                    ...baseCondition,
                    dependencyId: baseCondition.activityId,
                    dependencyType: 'end',
                    endCriteria: 'until_completion',
                    id,
                    offsetAfterDateAnchor: 0,
                    triggerType: ActivityDependencyTriggerType.ACTIVITY_COMPLETION,
                    ...(!isRemoteActivity && activitySequence && { dependencyInstanceId: activitySequence.instanceId }),
                });
                break;
            case EndingConditionType.END_OF_DAY:
                endConditions.push({
                    ...baseCondition,
                    dependencyType: 'end',
                    endCriteria: 'end_of_day',
                    id,
                    triggerType: ActivityDependencyTriggerType.END_OF_DAY,
                });
                break;
            case EndingConditionType.ACTIVITY_RESPONSE:
                endConditions.push({
                    ...baseCondition,
                    comparisonOperator: '==',
                    comparisonValue: condition.activityResponse.response.value,
                    dependencyId: condition.activityResponse.activity.meta?.activityId,
                    dependencyType: 'end',
                    id,
                    triggerScreen: condition.activityResponse.screen.label,
                    triggerScreenKey: condition.activityResponse.screen.value,
                    triggerType: ActivityDependencyTriggerType.SCREEN_RESPONSE,
                    ...(!isRemoteActivity && { dependencyInstanceId: condition.activityResponse.activity.value }),
                });
                break;
            case EndingConditionType.DATE_RESPONSE:
                endConditions.push({
                    ...baseCondition,
                    dependencyId: condition.activityResponse.activity.meta?.activityId,
                    dependencyType: 'end',
                    id,
                    offsetAfterDateAnchor: offset,
                    triggerScreen: condition.activityResponse.screen.label,
                    triggerScreenKey: condition.activityResponse.screen.value,
                    triggerType: ActivityDependencyTriggerType.DATE_SCREEN_RESPONSE,
                    ...(!isRemoteActivity && { dependencyInstanceId: condition.activityResponse.activity.value }),
                });
                break;
        }
    });
    return endConditions;
};
export const ActivityDependencyUtils = {
    convertEndConditions,
    convertStartConditions,
};
