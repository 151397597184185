import { AssetTypes, LocalizationStatus } from 'libs.localization.common';
import { Locale } from 'libs.nucleus.i18n';
import { useResourcesLoader } from './use_resources_loader.hook';
import { getTypeByAvailableTo, getRolebyAvailableTo } from '../utils/data_utils';
import { toSnakeCase } from '../utils/string_utils';
export const useResourcesData = (study, locales) => {
    const config = study.getConfiguration();
    const resources = config.studyMetadata.studyResources;
    const contentIds = resources?.filter((r) => Boolean(r.contentId)).map((r) => r.contentId) || [];
    const resourcesData = useResourcesLoader(contentIds);
    if (!resources?.length || !resourcesData.data.length || resourcesData.pending) {
        return {};
    }
    const data = {};
    resources.forEach(({ id, title, availableTo, contentId }) => {
        if (contentId) {
            const localeData = {};
            for (const locale of locales) {
                localeData[locale] = locale === Locale.en_US ? LocalizationStatus.READY : LocalizationStatus.NOT_STARTED;
            }
            const resourceData = resourcesData.data.find((r) => r.id === contentId);
            data[title.trim()] = {
                id,
                path: id,
                type: getTypeByAvailableTo(availableTo),
                role: getRolebyAvailableTo(availableTo),
                assetType: AssetTypes.RESOURCES,
                statuses: localeData,
                asset: {
                    type: AssetTypes.RESOURCES,
                    id,
                    version: '1',
                    human_id: toSnakeCase(title),
                    messages: [
                        {
                            namespace: id,
                            messages: {
                                value: resourceData.data.content,
                            },
                        },
                    ],
                    preview: {
                        engine: 'react',
                        id: AssetTypes.RESOURCES,
                        default_viewport: availableTo === 'site_only' ? 'iPadLandscape' : 'iosSmall',
                        info: {
                            display_name: title,
                            list: [],
                        },
                    },
                },
            };
        }
    });
    return data;
};
