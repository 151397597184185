import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
export const useNavigation = () => {
    const { studyId } = useParams();
    const translate = useLocalizeMessage();
    const navItems = useMemo(() => {
        const items = [
            // TODO: Site Dashboard should be added after MVP
            // { title: translate('Home'), path: `/site/study/${studyId}/home`, icon: 'navCube' },
            { title: translate('Participants'), path: `/site/study/${studyId}/participants`, icon: 'navUsers' },
            { title: translate('Visits'), path: `/site/study/${studyId}/visits`, icon: 'date' },
            { title: translate('Data Changes'), path: `/site/study/${studyId}/datachanges`, icon: 'navProtocol' },
            { title: translate('Study Data'), path: `/site/study/${studyId}/studydata`, icon: 'navData' },
            { title: translate('Resources'), path: `/site/study/${studyId}/resources`, icon: 'navWorkflows' },
        ];
        return items;
    }, []);
    return { navItems };
};
