export const buildDocumentUrl = (documentId, cluster, orgCode) => {
    if (!cluster) {
        return '';
    }
    return `https://site-${cluster}.medable.com/documents/${documentId}?orgCode=${orgCode}`;
};
export const buildDocumentCreateUrl = (participantId, cluster, orgCode) => {
    if (!cluster || !participantId) {
        return '';
    }
    return `https://site-${cluster}.medable.com/documents/create/participant/${participantId}?orgCode=${orgCode}`;
};
