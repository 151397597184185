import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { createContext, useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { getApiInstance } from '../../helpers/api.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
export const LegacyAccountContext = createContext({
    isLoading: true,
    account: null,
});
export const LegacyAccountProvider = ({ children }) => {
    const match = useMatch('/site/study/:studyId/*');
    const { environment } = useCurrentEnvironment(match?.params?.studyId);
    const getAxonAccount = async () => {
        if (!environment) {
            return;
        }
        const { data: response } = await getApiInstance(environment).get(`/v2/accounts/me`);
        return response || null;
    };
    const { data: account, isLoading } = useQuery({
        queryKey: ['getAxonAccount'],
        queryFn: getAxonAccount,
        enabled: !!environment,
    });
    const value = useMemo(() => ({
        isLoading,
        account,
    }), [account]);
    return _jsx(LegacyAccountContext.Provider, { value: value, children: children });
};
