import { jsx as _jsx } from "react/jsx-runtime";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CookiesProvider } from 'react-cookie';
import { ErrorBoundary } from 'react-error-boundary';
import { FirebaseAppProvider, FirebaseAuthProvider } from 'libs.firebase_react';
import { FlagContext } from 'libs.flags.react';
import { Locale } from 'libs.i18n-core';
import { I18nContextProvider } from 'libs.nucleus.i18n';
import { DialogProvider } from 'libs.nucleus.modal_dialog';
import { ToastNotificationProvider, InlineNotificationProvider } from 'libs.nucleus.notification';
import { AuthProvider } from 'libs.react.auth';
import { ApiClientProvider } from 'libs.react.contexts';
import { ErrorPage, Router } from './components';
import { firebaseEnvConfig } from './constants/firebase_app.config';
import { flagManager } from './utils';
const SUBDOMAIN_REGEX = /^[^.]+\./g;
const cookiesSetOptions = {
    domain: window.location.hostname.replace(SUBDOMAIN_REGEX, '.'),
    path: '/',
};
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: (retryCount, error) => (error.response.status === 401 ? false : retryCount < 2),
        },
    },
});
export const App = () => {
    const NucleusProviders = ({ children }) => {
        return (_jsx(I18nContextProvider, { locale: Locale.en_US, children: _jsx(ToastNotificationProvider, { position: 'top-end', children: _jsx(InlineNotificationProvider, { children: _jsx(DialogProvider, { children: children }) }) }) }));
    };
    return (_jsx(ErrorBoundary, { FallbackComponent: ErrorPage, children: _jsx(FlagContext.Provider, { value: flagManager, children: _jsx(CookiesProvider, { defaultSetOptions: cookiesSetOptions, children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(FirebaseAppProvider, { firebaseConfig: firebaseEnvConfig, children: _jsx(NucleusProviders, { children: _jsx(ApiClientProvider, { children: _jsx(AuthProvider, { children: _jsx(FirebaseAuthProvider, { children: _jsx(Router, {}) }) }) }) }) }) }) }) }) }));
};
