import axios from 'axios';
export const getApiInstance = (environment) => {
    const axiosInstance = axios.create({
        baseURL: `${environment.cortexURL}/${environment.code}`, // Replace with your API base URL
        headers: {
            'Medable-Client-Key': 'c_site_app_demo',
        },
        withCredentials: true,
    });
    return axiosInstance;
};
export const getApiErrorMessage = (error) => {
    const errorData = error?.response?.data;
    function findFaultMessage(faults) {
        for (const fault of faults) {
            if (fault.reason) {
                return fault.reason;
            }
            if (!fault.faults && fault.message) {
                return fault.message;
            }
            if (fault.faults) {
                return findFaultMessage(fault.faults);
            }
        }
    }
    if (errorData?.reason) {
        return errorData?.reason;
    }
    const faultError = findFaultMessage(errorData?.faults);
    return faultError;
};
