import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import { EM_DASH } from 'libs.constants';
import { Pill } from 'libs.nucleus.pill';
import { ParticipantStatuses } from '../constants/participants.constants';
export const getParticipantNumber = (rowData) => {
    if (rowData.c_number) {
        return rowData.c_number;
    }
    const { _id } = rowData;
    return _id.substring(_id.length - 6, _id.length);
};
export const getParticipantStatus = (status) => {
    if (status === ParticipantStatuses.CONSENTED) {
        return _jsx(Pill, { label: status, iconBackgroundColor: 'green', variant: 'status' });
    }
    return _jsx(Pill, { label: status, iconBackgroundColor: 'gray', variant: 'status' });
};
export const getParticipantNextVisitWindow = (participant) => {
    // todo: add localization for date format
    if (participant.c_next_visit_window_start && participant.c_next_visit_window_end) {
        const startDateFormatted = moment(participant.c_next_visit_window_start).format('YYYY-MMM-DD');
        const endDateFormatted = moment(participant.c_next_visit_window_end).format('YYYY-MMM-DD');
        return `${startDateFormatted} to ${endDateFormatted}`;
    }
    if (participant.c_next_visit_date) {
        return moment(participant.c_next_visit_date).format('YYYY-MMM-DD');
    }
    return EM_DASH;
};
export const formatVisitWindow = (param) => {
    if (!param.windowStartDate || !param.windowEndDate) {
        return EM_DASH;
    }
    const startDate = new Date(param.windowStartDate);
    const endDate = new Date(param.windowEndDate);
    const startMonthName = new Intl.DateTimeFormat(param.currentLocale, { month: 'long' }).format(startDate);
    const endMonthName = new Intl.DateTimeFormat(param.currentLocale, { month: 'long' }).format(endDate);
    const year = startDate.getFullYear();
    // Check if the months are the same
    let periodStr;
    if (startMonthName === endMonthName) {
        const period = startDate.getDate() === endDate.getDate() ? startDate.getDate() : `${startDate.getDate()}-${endDate.getDate()}`;
        periodStr = `${startMonthName} ${period}, ${year}`;
    }
    else {
        periodStr = `${startMonthName} ${startDate.getDate()} - ${endMonthName} ${endDate.getDate()}, ${year}`;
    }
    return periodStr;
};
