import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { EM_DASH } from 'libs.constants';
import { FirebaseAppContext } from 'libs.firebase_react';
import { FlagContext } from 'libs.flags.react';
import { Dropdown } from 'libs.nucleus.dropdown';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Link } from 'libs.nucleus.link';
import { useDialog } from 'libs.nucleus.modal_dialog';
import { Text } from 'libs.nucleus.typography';
import { AuthContext, checkScopes } from 'libs.react.auth';
import { AclScope } from 'libs.react.types';
import { STUDY_MANAGEMENT_FLAGS } from '../../constants';
import { StudiesContext } from '../../contexts';
export const useStudiesDashboardColumns = ({ onCloneStudy, onOpenShareModal }) => {
    const { removeStudy, studies } = useContext(StudiesContext);
    const flagManager = useContext(FlagContext);
    const { entityId, scopes } = useContext(AuthContext);
    const { logEvent } = useContext(FirebaseAppContext);
    const canCloneStudies = flagManager.getValue(STUDY_MANAGEMENT_FLAGS.cloneStudies);
    const dialog = useDialog();
    const translate = useLocalizeMessage();
    const onDeleteStudy = async (id) => {
        const ok = await dialog.open({
            title: translate('Delete Study'),
            body: translate('Are you sure you want to delete this study? This action cannot be undone.'),
            actionButtons: [{ isDestructive: true, label: translate('Delete') }, { label: translate('Cancel') }],
        });
        if (ok) {
            removeStudy(id);
            logEvent('study_deleted', { study_id: id });
        }
    };
    return useMemo(() => [
        {
            header: 'Study name',
            accessorKey: 'name',
            size: 150,
            cell: ({ row }) => (_jsx(Link, { LinkComponent: RouterLink, to: `/studies/${row.original.id}/build`, children: row.original.name })),
        },
        {
            header: 'Protocol number',
            size: 200,
            cell: ({ row }) => row.original.metadata?.protocolNumber || EM_DASH,
        },
        {
            header: 'Description',
            size: 300,
            cell: ({ row }) => (_jsx(Text, { size: 'sm', className: 'text-text-primary line-clamp-2', children: row.original.metadata?.description || EM_DASH })),
        },
        {
            header: 'Owner',
            size: 200,
            cell: ({ row }) => row.original.owner.email || EM_DASH,
        },
        {
            id: 'options',
            size: 20,
            cell: ({ row }) => {
                const canShareStudy = checkScopes({
                    scopes,
                    permission: AclScope.MOM_STUDY_SET_IAM_POLICY,
                    resource: `entity/${entityId}/study/${row.original.id}`,
                });
                const dropdownItems = [
                    {
                        id: 'share',
                        content: translate('Share'),
                        disabled: !canShareStudy,
                        onClick: () => onOpenShareModal(String(row.original.id)),
                    },
                    {
                        id: 'delete',
                        content: translate('Delete'),
                        onClick: () => onDeleteStudy(row.original.id),
                        destructive: true,
                    },
                ];
                if (canCloneStudies) {
                    dropdownItems.unshift({
                        id: 'clone',
                        content: translate('Clone'),
                        onClick: () => onCloneStudy(String(row.original.id)),
                    });
                }
                return (_jsx("section", { className: 'flex flex-col items-end', children: _jsx(Dropdown, { position: 'bottom-end', items: dropdownItems, variant: 'kebab' }) }));
            },
        },
    ], [studies]);
};
